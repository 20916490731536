/* eslint-disable */
import React, { FC, useState } from 'react';
import { ReactComponent as Arrow } from 'src/assets/images/icon-accordion.svg';
import { ICountryResource, ITournamentResource } from 'src/views/Sport/type';
import { getTournament } from 'src/core/engines/SportEngine';
import { ReactComponent as Loading } from 'src/assets/images/loading.svg';
import './CountryAccardion.scss';

interface IProps {
    item: ICountryResource;
    sportId: number;
}

const CountryAccardion: FC<IProps> = ({ item, sportId }) => {
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tournament, setTournament] = useState<ITournamentResource[]>([]);

    const handleOpenCloseAccardion = async () => {
        if (show === false) {
            setIsLoading(true);
            const resp = await getTournament(sportId, item.id);
            setTournament(resp);
            setIsLoading(false);
        }
        setShow(!show);
    };

    return (
        <div key={item.id} className="CountryAccardion">
            <div className="CountryAccardion-item" onClick={handleOpenCloseAccardion}>
                <div className="CountryAccardion-item-content">
                    <img src={item.logo} alt="" className="CountryAccardion-item-logo" />
                    <span>{item.name}</span>
                </div>
                {isLoading && <Loading className="CountryAccardion-loading" />}
                <div className={show ? 'CountryAccardion-item-arrow-active' : 'CountryAccardion-item-arrow'}>
                    {!isLoading && <Arrow />}
                </div>
            </div>

            {show && (
                <div className="CountryAccardion-list">
                    {tournament.map((item) => (
                        <div className="CountryAccardion-list-item">
                            <div className="CountryAccardion-list-item-content">
                                <div className="CountryAccardion-list-item-logo" />
                                <span>{item.name}</span>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CountryAccardion;
