import copy from 'copy-to-clipboard';
import { t } from 'i18next';
import React, { FC, useCallback } from 'react';
import './newPassword.scss';
import { NotificationManager } from 'react-notifications';
import copyimg from 'src/assets/images/copy.svg';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';

const NewPassword: FC = (): JSX.Element => {
    const password = sessionStorage.getItem('newPassword');

    const onCopy = useCallback(() => {
        NotificationManager.success(`${t('copied')}`, '', 2000);
        copy(password);
    }, [password]);

    return (
        <div className="NewPassword">
            <div className="NewPassword-xmark" onClick={() => appDispatch(setCloseModalTTT(String(modalTypes.NEW_PASSWORD)))}>
                ✕
            </div>
            <span className="NewPassword-content-title">{t('your_new_password')}</span>
            <div className="NewPassword-content">
                <span onClick={onCopy}>
                    {password} <img src={copyimg} alt="copy" className="NewPassword-copy-icon" />
                </span>
            </div>
        </div>
    );
};

export default NewPassword;
