import { api } from 'src/api/api';
import { setSports } from 'src/core/store/reducers/sportSlice';
import store from 'src/core/store/store';
import { countryMap, countryCode } from 'src/common/countries.ts';

export const getListSport = async () => {
    const resp = await api.get(`/provider/sport/v1/sport/list`);
    if (resp.data?.list) {
        store.dispatch(setSports(resp.data.data.list));
    }
};

export const getMatchBySport = async (sport_id, status) => {
    const resp = await api.get(`/provider/sport/v1/match/list?sport_id=${sport_id}&status_id=${status}`);
    if (resp.data) {
        return resp.data;
    }
};

export const getTournament = async (sport_id, country_id) => {
    const resp = await api.get(`/provider/sport/v1/tournament/list?sport_id=${sport_id}&country_id=${country_id}`);
    if (resp.data) {
        return resp.data.data.list;
    }
};

export const getContries = async () => {
    const resp = await api.get(`/provider/sport/v1/country/list`);
    if (resp.data?.list) {
        return resp.data.data.list.filter((item) => countryCode.includes(countryMap[item.name]));
    }
};
