import { api } from 'src/api/api';
import { setUser } from 'src/core/store/reducers/userSlice';
import { setBalance } from 'src/core/store/reducers/balanceSlice';
import store from 'src/core/store/store';
import { getStatus } from './AppEngine';

export const getProfile = async () => {
    const { data } = await api.get('/auth/profile');
    if (data) {
        getStatus();
        store.dispatch(setUser({ ...data }));
    }
    return { data };
};

export const getHistory = async (options) => {
    for (const key in options) {
        if (options[key] === null) {
            delete options[key];
        }
    }
    const query = new URLSearchParams(options).toString();
    const response = await api.get(`/balance?${query}`);
    if (response.data) {
        store.dispatch(setBalance(response.data));
    }

    return { response };
};
