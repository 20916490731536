import './appLayout.scss';
import Header from 'src/components/header/Header';
import Drawer from 'react-modern-drawer';
import { setDrawerRightOpen, setDrawerLeftOpen } from 'src/core/store/reducers/appSlice';
import { setAuthToken } from 'src/utils/cookies';
import 'react-modern-drawer/dist/index.css';
import 'react-notifications/lib/notifications.css';
import { useSelector, useDispatch } from 'react-redux';
import MobileRightSideMenu from 'src/components/mobileRightSideMenu/MobileRightSideMenu';
import MobileLeftSideMenu from 'src/components/mobileLeftSideMenu/MobileLeftSideMenu';
import { NotificationContainer } from 'react-notifications';
import Modal from 'src/components/modals/Modal';
import { useLocation } from 'react-router-dom';
import { initData } from '../../core/engines/AppEngine';
import Games from '../games';

function AppLayout({ children }) {
    const drawerLeftOpen = useSelector((state) => state.app.drawerLeftOpen);
    const drawerRightOpen = useSelector((state) => state.app.drawerRightOpen);
    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);
    const dispatch = useDispatch();

    const drawerGeneralProps = {
        size: '100vw',
    };
    const location = useLocation();

    const loadUserData = async () => {
        const searchParams = new URLSearchParams(location.search);
        const token = searchParams.get('token');

        if (token) {
            await setAuthToken(token);
            const newUrl = window.location.pathname;
            window.history.replaceState({}, document.title, newUrl);
            initData();
        }
    };

    return (
        <div className="AppLayout">
            <div className="shine3" />
            <div className="shine2" />
            <div className="shine" />
            <div className="AppLayout--chidlren-container">
                <Drawer
                    open={drawerRightOpen}
                    onClose={() => dispatch(setDrawerRightOpen(!drawerRightOpen))}
                    direction="right"
                    className="drawer"
                    {...drawerGeneralProps}
                >
                    <MobileRightSideMenu />
                </Drawer>
                <Drawer
                    open={drawerLeftOpen}
                    onClose={() => dispatch(setDrawerLeftOpen(!drawerLeftOpen))}
                    direction="right"
                    className="drawer drawer-left"
                    {...drawerGeneralProps}
                >
                    <MobileLeftSideMenu />
                </Drawer>
                <Modal />
                <Header />
                <Games />

                <NotificationContainer />
                {children}
            </div>
        </div>
    );
}

export default AppLayout;
