import hearts from 'src/assets/images/cards/hearts.png';
import tambourine from 'src/assets/images/cards/tambourine.png';
import queen from 'src/assets/images/cards/queen.png';
import cross from 'src/assets/images/cards/cross.png';

export const getYears = Array.from({ length: 51 }, (_, i) => {
    const year = new Date().getFullYear() - 18 - i;

    return {
        value: year,
        label: year,
    };
});

export const getMount = Array.from({ length: 12 }, (_, i) => {
    const mount = i + 1;
    return {
        value: mount,
        label: mount,
    };
});

export const getDays = Array.from({ length: 31 }, (_, i) => {
    const day = i + 1;
    return {
        value: day,
        label: day,
    };
});

export const monthsDays = {
    1: 31,
    2: 29,
    3: 31,
    4: 30,
    5: 31,
    6: 30,
    7: 31,
    8: 31,
    9: 30,
    10: 31,
    11: 30,
    12: 31,
};

export const cards = [
    [
        { title: '7', value: 7, key: 'first' },
        { title: '8', value: 8, key: 'first' },
        { title: '9', value: 9, key: 'first' },
        { title: '10', value: 10, key: 'first' },
        { title: 'J', value: 11, key: 'first' },
        { title: 'Q', value: 12, key: 'first' },
        { title: 'K', value: 13, key: 'first' },
        { title: 'A', value: 14, key: 'first' },
        { images: queen },
    ],
    [
        { title: '7', value: 7, key: 'second' },
        { title: '8', value: 8, key: 'second' },
        { title: '9', value: 9, key: 'second' },
        { title: '10', value: 10, key: 'second' },
        { title: 'J', value: 11, key: 'second' },
        { title: 'Q', value: 12, key: 'second' },
        { title: 'K', value: 13, key: 'second' },
        { title: 'A', value: 14, key: 'second' },
        { images: hearts },
    ],
    [
        { title: '7', value: 7, key: 'third' },
        { title: '8', value: 8, key: 'third' },
        { title: '9', value: 9, key: 'third' },
        { title: '10', value: 10, key: 'third' },
        { title: 'J', value: 11, key: 'third' },
        { title: 'Q', value: 12, key: 'third' },
        { title: 'K', value: 13, key: 'third' },
        { title: 'A', value: 14, key: 'third' },
        { images: tambourine },
    ],
    [
        { title: '7', value: 7, key: 'fourth' },
        { title: '8', value: 8, key: 'fourth' },
        { title: '9', value: 9, key: 'fourth' },
        { title: '10', value: 10, key: 'fourth' },
        { title: 'J', value: 11, key: 'fourth' },
        { title: 'Q', value: 12, key: 'fourth' },
        { title: 'K', value: 13, key: 'fourth' },
        { title: 'A', value: 14, key: 'fourth' },
        { images: cross },
    ],
];
