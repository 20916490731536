import React, { useState, useEffect } from 'react';
import './outlinedDropdown.scss';
import arrowImg from 'src/assets/images/arrow.png';
import classNames from 'classnames';
import { useDetectClickOutside } from 'react-detect-click-outside';
import { useTranslation } from 'react-i18next';

function OutlinedDropdown({
    options,
    onSelect,
    defaultSelected,
    className,
    placeholder = 'Select',
    disabled = false,
    small = false,
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const ref = useDetectClickOutside({ onTriggered: () => setIsOpen(false) });
    const { t } = useTranslation();

    useEffect(() => {
        setSelectedOption(defaultSelected);
    }, [defaultSelected]);

    const toggleoDropdown = () => {
        if (disabled) return;
        setIsOpen(!isOpen);
    };

    const handleOptionSelect = (option) => {
        if (disabled) return;
        if (option.value === selectedOption?.value) return;
        setSelectedOption(option);
        setIsOpen(false);
        if (onSelect) {
            onSelect(option);
        }
    };

    return (
        <div
            className={classNames('outlinedDropdown', className, {
                ['outlinedDropdown--toggled']: isOpen,
                ['outlinedDropdown-small']: small,
            })}
            ref={ref}
        >
            <div className="outlinedDropdown-toggle" onClick={toggleoDropdown}>
                <span className="outlinedDropdown-selected">{selectedOption ? selectedOption?.label : placeholder}</span>
                <img
                    src={isOpen ? arrowImg : arrowImg}
                    alt="arrow"
                    className={classNames('arrow', { ['arrow--toggled']: isOpen })}
                />
            </div>
            {isOpen && (
                <div className="outlinedDropdown-options-wrapper">
                    {options.length ? (
                        options.map((option) => (
                            <div
                                className={classNames('outlinedDropdown-option', {
                                    ['outlinedDropdown-option--disabled']: option.value === selectedOption?.value,
                                })}
                                key={option.value}
                                onClick={() => handleOptionSelect(option)}
                            >
                                {option.label}
                            </div>
                        ))
                    ) : (
                        <span className="outlinedDropdown-nooption">{t('no_options')}</span>
                    )}
                </div>
            )}
        </div>
    );
}

export default OutlinedDropdown;
