import { getCountryCallingCode } from 'libphonenumber-js';
import React, { ChangeEvent, useState } from 'react';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { api } from 'src/api/api';
import Button from 'src/components/button/Button';
import Input from 'src/components/input/Input';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModal } from 'src/core/store/reducers/appSlice';

import { ResponseRegister } from './types';
import { validatePhoneNumber, validationLogin, validationPassword } from './validation';

function Register() {
    const countryCode = 'IL';
    const phoneCodeCountry = `+${getCountryCallingCode(countryCode)}`;
    const [username, setUsername] = useState('');
    const [step, setStep] = useState(1);
    const [phone, setPhone] = useState(phoneCodeCountry);
    const [code, setCode] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [passwordSign, setPasswordSign] = useState('');
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const getCode = async () => {
        setIsLoading(true);
        const options = {
            login: username,
            phone,
        };

        const queryProp = new URLSearchParams(options).toString();

        const resp: ResponseRegister = await api.get(`/user/signup/code?${queryProp}`);
        if (resp.data) {
            // NotificationManager.success(`${t('sent_code')}`, '', 3500);
            // TODO View code
            NotificationManager.warning(`${t('code')}: ${resp.data.codePayload}`, '', 20000);
            setStep(2);
            setIsLoading(false);
        } else {
            switch (resp?.error?.response?.status) {
                case 404:
                    setStep(1);
                    break;
                case 403:
                case 405:
                case 406:
                    setStep(2);
                    break;

                default:
                    break;
            }
            setIsLoading(false);
        }
    };

    const register = async () => {
        setIsLoading(true);
        const options = {
            login: username,
            phone,
            codePayload: code,
            password,
            passwordSign,
        };
        const resp: ResponseRegister = await api.post('/user/signup', options);
        if (resp.data) {
            NotificationManager.success(`${t('register_success')}`, '', 3500);
            dispatch(setCloseModal(modalTypes.REGISTER));
            setIsLoading(false);
        } else {
            if (resp?.error?.response?.status === 404) {
                setStep(1);
            }
            setIsLoading(false);
        }
    };

    const handleChangePhone = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (value.startsWith(phoneCodeCountry)) {
            const formattedValue = value.replace(/[^+\d]/g, '');

            if (formattedValue.length < 4) return;

            setPhone(formattedValue);
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const handleChangeLogin = (e: ChangeEvent<HTMLInputElement>) => {
        setUsername(e.target.value.toLowerCase());
    };

    const isDisabledButton = () => {
        if (step === 1) {
            if (typeof validationLogin(username) === 'string' || username.length === 0) return true;
            if (typeof validatePhoneNumber(phone) === 'string' || phone.length === 0) return true;
            return false;
        }

        if (step === 2) {
            if (code.length !== 8) return true;
            return false;
        }

        if (step === 3) {
            if (typeof validationPassword(password) === 'string' || password.length === 0) return true;
            if (typeof validationPassword(passwordSign) === 'string' || passwordSign.length === 0) return true;
            if (password !== passwordSign) return true;
            return false;
        }
    };

    const factoryTitleButton = () => {
        switch (step) {
            case 1:
                return t('get_code');

            case 2:
                return t('next');

            case 3:
                return t('register');

            default:
                return '';
        }
    };

    const factoryActionButton = () => {
        switch (step) {
            case 1:
                return getCode;

            case 2:
                return nextStep;

            case 3:
                return register;

            default:
                return '';
        }
    };

    return (
        <div className="Registers">
            <div className="Registers-xmark" onClick={() => dispatch(setCloseModal(modalTypes.REGISTER))}>
                ✕
            </div>
            <div className="Registers-content">
                {(step === 1 || step === 2) && (
                    <div className="Registers-content-inputs">
                        <Input
                            name="login"
                            className="Registers-content-inputs-input"
                            label={t('login_name')}
                            placeholder={t('login_name')}
                            value={username}
                            onChange={handleChangeLogin}
                            error={validationLogin(username)}
                            readOnly={step !== 1}
                        />
                        <Input
                            name="phone"
                            className="Registers-content-inputs-input"
                            label={t('phone')}
                            placeholder={t('phone')}
                            value={phone}
                            onChange={handleChangePhone}
                            error={validatePhoneNumber(phone)}
                            readOnly={step !== 1}
                        />

                        {step === 2 && (
                            <div className="Registers-content-wrapper-code">
                                <span>{t('code')}:</span>
                                <Input
                                    name="number"
                                    hideLabel
                                    className="Registers-content-wrapper-code-input"
                                    value={code}
                                    placeholder={t('code')}
                                    onChange={(event: { target: HTMLInputElement }) => {
                                        setCode(event.target.value);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}

                {step === 3 && (
                    <div className="Registers-content-inputs">
                        <Input
                            name="new_password"
                            className="Registers-content-inputs-input"
                            label={t('new_password')}
                            placeholder={t('new_password')}
                            value={password}
                            type="password"
                            onChange={(event: { target: HTMLInputElement }) => {
                                setPassword(event.target.value);
                            }}
                            error={validationPassword(password)}
                        />
                        <Input
                            name="phone"
                            className="Registers-content-inputs-input"
                            label={t('repeat_password')}
                            placeholder={t('repeat_password')}
                            type="password"
                            value={passwordSign}
                            onChange={(event: { target: HTMLInputElement }) => {
                                setPasswordSign(event.target.value);
                            }}
                            error={validationPassword(passwordSign)}
                        />
                    </div>
                )}
                <div className="Registers-buttons">
                    {step === 3 && (
                        <Button className="Registers-content-signin" title={t('cancel')} onClick={prevStep} loading={isLoading} />
                    )}

                    <Button
                        className="Registers-content-signin"
                        title={factoryTitleButton()}
                        primary
                        onClick={factoryActionButton()}
                        disbaled={isDisabledButton()}
                        loading={isLoading}
                    />
                </div>
            </div>
        </div>
    );
}

export default Register;
