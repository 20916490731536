import Sliders from '../../components/slider';
import black from '../../assets/images/black.svg';
import sport from '../../assets/images/main/sport.jpg';
import livecasino from '../../assets/images/main/live_casino.jpg';
import casino from '../../assets/images/main/CASINO.jpg';
import livesport from '../../assets/images/main/live_sport.jpg';
import slotcasino from '../../assets/images/main/slot_casino.jpg';
import virtualgames from '../../assets/images/main/virtual_games.jpg';
import './main.scss';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
function Main() {
    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);
    useSelector((state) => state.user);
    const navigate = useNavigate();
    const handleClickGame = (link) => {
        navigate(link);
    };

    return (
        <div className="Main">
            <div className="Main-content">
                {/*{isAuthorizedState && (*/}
                <div className="Main-submenu" id="first">
                    <div className="Main-submenu-container">
                        <div className="Main-submenu-item">
                            <img className="Main-submenu-icon" src={sport} alt="" />
                            <a>{t('sport')}</a>
                        </div>
                        <div className="Main-submenu-item" onClick={() => handleClickGame('/live-casino')}>
                            <img className="Main-submenu-icon" src={livecasino} alt="" />
                            <a>{t('live-casino')}</a>
                        </div>
                        <div className="Main-submenu-item">
                            <img className="Main-submenu-icon" src={casino} alt="" />
                            <a>{t('casino')}</a>
                        </div>
                        <div className="Main-submenu-item">
                            <img className="Main-submenu-icon" src={livesport} alt="" />
                            <a>{t('live-sport')}</a>
                        </div>
                        <div className="Main-submenu-item">
                            <img className="Main-submenu-icon" src={slotcasino} alt="" />
                            <a>{t('slot-casino')}</a>
                        </div>
                        <div className="Main-submenu-item">
                            <img className="Main-submenu-icon" src={virtualgames} alt="" />
                            <a>{t('virtual-games')}</a>
                        </div>
                    </div>
                </div>
                {/*)}*/}
                <Sliders />
            </div>
        </div>
    );
}

export default Main;
