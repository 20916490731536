import { ChanceDictionary } from "./chance";
// import { GameTypeEnum } from "../../common-be/dictionary/bog/game-type";
// import { GameTypeEnum as LiveCasinoGameTypeEnum } from "../../common-be/dictionary/live-casino/enum/game-type";
// import { GameTypeEnum as RacingGameTypeEnum } from "../../common-be/dictionary/racing/game-type";

/* eslint-disable no-unused-vars */
export class SystemProviderDictionary {
  static SYSTEM_BALANCE = 1;
  static PROVIDER_WALLET = 2;
  static SYSTEM_GAME = 10;
  static PROVIDER_RACING = 11;
  static PROVIDER_CASINO = 12;
  static PROVIDER_SPORT = 13;
}

export enum SystemProviderEnum {
  SYSTEM_BALANCE = SystemProviderDictionary.SYSTEM_BALANCE,
  PROVIDER_WALLET = SystemProviderDictionary.PROVIDER_WALLET,
  SYSTEM_GAME = SystemProviderDictionary.SYSTEM_GAME,
  PROVIDER_RACING = SystemProviderDictionary.PROVIDER_RACING,
  PROVIDER_CASINO = SystemProviderDictionary.PROVIDER_CASINO,
  PROVIDER_SPORT = SystemProviderDictionary.PROVIDER_SPORT
}

// Game types
// export const CombinedGameTypeEnum = { ...ChanceTypeEnum, ...GameTypeEnum, ...LiveCasinoGameTypeEnum, ...RacingGameTypeEnum };

// eslint AssertionError, enum keys must be without quotes. In this case not accepted symbols:["-", " "], use "_" instead
export enum GlobalGameTypeEnum {
  GAME_TYPE_CHANCE = ChanceDictionary.CHANCE_TYPE,
  CHANCE_TYPE_SPEED = ChanceDictionary.CHANCE_TYPE_SPEED,
  // API Live-Casino GameTypeEnum
  "Live Casino" = 2,
  // API racing GameTypeEnum
  "OT" = 777,
  // API Sport sports (originalGameType+=1000)
  "Football" = 1001,
  "Basketball" = 1002,
  "Tennis" = 1003,
  "Baseball" = 1006,
  "Ice Hockey" = 1008,
  "Volleyball" = 1010
}
