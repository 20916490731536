import Button from 'src/components/button/Button';
import './history.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modalTypes } from 'src/components/modals/Modal';
import DatePickerComponent from 'src/components/DatePickerComponent/DatePickerComponent';
import { setCloseModal } from 'src/core/store/reducers/appSlice';
import { getHistory } from 'src/core/engines/UserEngine';
import Input from 'src/components/input/Input';
import { useEffect, useState } from 'react';
import arrowImg from 'src/assets/images/arrow.png';
import OutlinedDropdown from 'src/components/outlinedDropdown/OutlinedDropdown';
import { dateToTimestamp, formatUserTimeZone } from 'src/utils/helpers';
import ReactPaginate from 'react-paginate';
import { validationGameName } from './validate';
import { Collapse } from 'react-collapse';
import { GlobalGameTypeEnum, SystemProviderEnum } from '../../../common/provider-common-type-util/dictionary/system-provider';
import { BalanceProviderTypeEnum, BalanceTypeEnum } from '../../../common/provider-common-type-util/dictionary/balance';

function History() {
    const user = useSelector((state) => state.user.user);
    const balance = useSelector((state) => state.balance.balance);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isMobile = window.innerWidth <= 900;
    const defaulStateIsOpen = isMobile ? false : true;
    const [page, setPage] = useState(1);
    const [isOpen, setIsOpen] = useState(defaulStateIsOpen);
    const [isLoading, setIsLoading] = useState(false);

    const [historyOption, setHistoryOption] = useState({
        startDate: new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        endDate: new Date(),
        gameName: '',
        gameProvider: '',
        providerGameId: '',
        providerTransactionId: '',
    });

    const amountPerPage = 15;

    const [selectedBalanceType, setSelectedBalanceType] = useState();
    const [selectedBalanceProviderType, setSelectedBalanceProviderType] = useState();
    const [selectedGame, setSelectedGame] = useState();

    const loadHistory = async (page, first) => {
        if (!first) {
            setIsLoading(true);
        }
        const options = {
            pageNumber: page,
            timeTo: dateToTimestamp(historyOption.endDate),
            timeFrom: dateToTimestamp(historyOption.startDate),
            target: user.id,
            amountPerPage: amountPerPage,
            type: selectedBalanceType?.value || null,
            providerType: selectedBalanceProviderType?.value || null,
            providerGameType: selectedGame?.value || null,
            providerGameName: historyOption.gameName || null,
            gameProvider: historyOption.gameProvider || null,
            providerGameId: historyOption.providerGameId || null,
            providerTransactionId: historyOption.providerTransactionId || null,
        };

        setPage(page);

        await getHistory(options);
        setIsLoading(false);
        if (isMobile) {
            setIsOpen(false);
        }
    };

    const handleChangeStartDate = (dates) => {
        const [start, end] = dates;
        setHistoryOption({ ...historyOption, startDate: start, endDate: end });
    };

    const handleToggle = () => {
        setIsOpen(!isOpen);
    };

    const handleSelectBalaceType = (type) => {
        setSelectedBalanceType(type);
    };

    const handleSelectBalaceProviderType = (type) => {
        setSelectedBalanceProviderType(type);
    };

    const handleSelectGameType = (type) => {
        setSelectedGame(type);
    };

    useEffect(() => {
        loadHistory(page, true);
    }, []);

    const factoryNameSystemProvider = (value) => {
        switch (value) {
            case SystemProviderEnum.SYSTEM_BALANCE:
                return 'System balance';
            case SystemProviderEnum.PROVIDER_CASINO:
                return 'Provider casino';
            case SystemProviderEnum.PROVIDER_RACING:
                return 'Provider RACING';
            case SystemProviderEnum.SYSTEM_GAME:
                return 'System Game';
            // case SystemProviderEnum.PROVIDER_BITHIDE:
            //     return 'Provider Bithide';

            default:
                return '-';
        }
    };

    const changePage = async (page) => {
        const currentPage = page.selected + 1;
        await setPage(currentPage);
        loadHistory(currentPage);
    };

    const getOptionBalanceType = () => {
        const enumKeys = Object.keys(BalanceTypeEnum)
            .filter((key) => isNaN(Number(BalanceTypeEnum[key])))
            .map((key) => ({
                label: BalanceTypeEnum[key],
                value: key,
            }));
        return [{ label: t('not_selected'), value: null }, ...enumKeys];
    };

    const getOptionBalanceProviderType = () => {
        const enumKeys = Object.keys(BalanceProviderTypeEnum)
            .filter((key) => isNaN(Number(BalanceProviderTypeEnum[key])))
            .map((key) => ({
                label: BalanceProviderTypeEnum[key],
                value: key,
            }));
        return [{ label: t('not_selected'), value: null }, ...enumKeys];
    };

    const getOptionGameType = () => {
        const enumKeys = Object.keys(GlobalGameTypeEnum)
            .filter((key) => isNaN(Number(GlobalGameTypeEnum[key])))
            .map((key) => ({
                label: GlobalGameTypeEnum[key],
                value: key,
            }));
        return [{ label: t('not_selected'), value: null }, ...enumKeys];
    };

    const handleChangeInput = (e) => {
        const key = e.target.id;
        setHistoryOption({ ...historyOption, [key]: e.target.value });
    };

    return (
        <div className="History" style={balance?.data?.balances.length === 0 ? { width: '100%' } : {}}>
            <span className="History-title">{t('history')}</span>
            <div className="History-collapse">
                <div className="History-collapse-header">
                    <span>{t('filters')}</span>
                    <img
                        onClick={handleToggle}
                        className={isOpen ? `History-collapse-icon` : 'History-collapse-icon-close'}
                        src={arrowImg}
                        alt=""
                    />
                </div>
                <Collapse isOpened={isOpen} theme={{ collapse: 'foo', content: 'bar' }}>
                    <div className="History-selectors">
                        <div className="History-xmark" onClick={() => dispatch(setCloseModal(modalTypes.HISTORY))}>
                            ✕
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('balance_type')}</span>
                            <OutlinedDropdown
                                options={getOptionBalanceType()}
                                defaultSelected={selectedBalanceType}
                                className="History-dropdown"
                                onSelect={handleSelectBalaceType}
                                placeholder={t('balance_type')}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('provider_type')}</span>
                            <OutlinedDropdown
                                options={getOptionBalanceProviderType()}
                                defaultSelected={selectedBalanceProviderType}
                                className="History-dropdown"
                                onSelect={handleSelectBalaceProviderType}
                                placeholder={t('provider_type')}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('game_type')}</span>
                            <OutlinedDropdown
                                options={getOptionGameType()}
                                defaultSelected={selectedGame}
                                className="History-dropdown"
                                onSelect={handleSelectGameType}
                                placeholder={t('game_type')}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('game_name')}</span>
                            <Input
                                className="History-input"
                                hideLabel
                                placeholder={t('game_name')}
                                value={historyOption.gameName}
                                id="gameName"
                                onChange={handleChangeInput}
                                error={validationGameName(historyOption.gameName)}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('game_provider')}</span>
                            <Input
                                className="History-input"
                                hideLabel
                                placeholder={t('game_provider')}
                                value={historyOption.gameProvider}
                                id="gameProvider"
                                onChange={handleChangeInput}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('provider_game_id')}</span>
                            <Input
                                className="History-input"
                                hideLabel
                                placeholder={t('provider_game_id')}
                                value={historyOption.providerGameId}
                                id="providerGameId"
                                onChange={handleChangeInput}
                            />
                        </div>
                        <div className="History-selectors-item">
                            <span className="History-selectors-item-title">{t('provider_transaction_id')}</span>
                            <Input
                                className="History-input"
                                hideLabel
                                placeholder={t('provider_transaction_id')}
                                value={historyOption.providerTransactionId}
                                id="providerTransactionId"
                                onChange={handleChangeInput}
                            />
                        </div>
                        <div className="History-selectors-dates">
                            <span className="History-selectors-item-title">{t('date')}</span>
                            <DatePickerComponent
                                className="History-date-picker"
                                onChange={handleChangeStartDate}
                                startDate={historyOption.startDate}
                                endDate={historyOption.endDate}
                            />
                        </div>
                        <Button
                            title={t('confirm')}
                            className="History-selectors-button"
                            primary
                            loading={isLoading}
                            onClick={() => loadHistory(1)}
                        />
                    </div>
                </Collapse>
            </div>
            {balance?.data?.balances.length !== 0 ? (
                <div className="History-table">
                    <div className="History-table-heading">
                        <div className="History-table-heading-item">{t('balance_type')}</div>
                        <div className="History-table-heading-item">{t('provider_type')}</div>
                        <div className="History-table-heading-item">{t('date')}</div>
                        <div className="History-table-heading-item">{t('amount')}</div>
                        <div className="History-table-heading-item">{t('targetCurrentBalance')}</div>
                        <div className="History-table-heading-item">{t('system_provider')}</div>
                        <div className="History-table-heading-item">{t('game_provider')}</div>
                        <div className="History-table-heading-item">{t('game_type')}</div>
                        <div className="History-table-heading-item">{t('game_name')}</div>
                    </div>
                    <div className="History-table-body">
                        {balance?.data?.balances.map((item) => (
                            <div className="History-table-row" key={item.id}>
                                <div className="History-table-row-item">{BalanceTypeEnum[item.type]}</div>
                                <div className="History-table-row-item">
                                    {item.providerType ? BalanceProviderTypeEnum[item.providerType] : '-'}
                                </div>
                                <div className="History-table-row-item">
                                    {formatUserTimeZone(item.createDate).split('T')[0]}{' '}
                                    {item.createDate.split('T')[1].split('.')[0]}
                                </div>
                                <div className="History-table-row-item">{item.amount}</div>
                                <div className="History-table-row-item">{item.targetCurrentBalance}</div>
                                <div className="History-table-row-item">{factoryNameSystemProvider(item.systemProvider)}</div>
                                <div className="History-table-row-item">{item.gameProvider}</div>
                                <div className="History-table-row-item">{GlobalGameTypeEnum[item.providerGameType]}</div>
                                <div className="History-table-row-item">{item.providerGameName}</div>
                            </div>
                        ))}
                    </div>
                </div>
            ) : (
                <div className="History-no-content">
                    <span>{t('no_data')}</span>
                </div>
            )}
            {balance?.totalCount > amountPerPage && (
                <ReactPaginate
                    previousLabel="<"
                    nextLabel=">"
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakLabel="..."
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    pageCount={balance?.totalCount / amountPerPage}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={1}
                    onPageChange={changePage}
                    containerClassName="pagination"
                    activeClassName="active"
                    forcePage={page - 1}
                />
            )}
        </div>
    );
}

export default History;
