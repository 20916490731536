/* eslint-disable no-unused-vars */
export class PermissionDictionary {
  // User Roles permission_level
  static USER_PLAYER_PERMISSION_LEVEL: number = 1;
  static USER_AGENT_PERMISSION_LEVEL: number = 2;
  static USER_MASTER_PERMISSION_LEVEL: number = 4;
  static USER_ADMIN_PERMISSION_LEVEL: number = 8;
  static USER_SERVICE_PERMISSION_LEVEL: number = 16;
}

export enum PermissionEnum {
  USER_PLAYER_PERMISSION_LEVEL = PermissionDictionary.USER_PLAYER_PERMISSION_LEVEL,
  USER_AGENT_PERMISSION_LEVEL = PermissionDictionary.USER_AGENT_PERMISSION_LEVEL,
  USER_MASTER_PERMISSION_LEVEL = PermissionDictionary.USER_MASTER_PERMISSION_LEVEL,
  USER_ADMIN_PERMISSION_LEVEL = PermissionDictionary.USER_ADMIN_PERMISSION_LEVEL,
  USER_SERVICE_PERMISSION_LEVEL = PermissionDictionary.USER_SERVICE_PERMISSION_LEVEL
}
