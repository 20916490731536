/* eslint-disable no-unused-vars */
export class DocumentDictionary {
  // Document status
  static DOCUMENT_STATUS_CREATED: number = 1;
  static DOCUMENT_STATUS_APPROVED: number = 2;
  static DOCUMENT_STATUS_REJECTED: number = 3;
}

export enum DocumentStatusEnum {
  DOCUMENT_STATUS_CREATED = DocumentDictionary.DOCUMENT_STATUS_CREATED,
  DOCUMENT_STATUS_APPROVED = DocumentDictionary.DOCUMENT_STATUS_APPROVED,
  DOCUMENT_STATUS_REJECTED = DocumentDictionary.DOCUMENT_STATUS_REJECTED
}
