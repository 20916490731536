import './loginActonsMobile.scss';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modalTypes } from 'src/components/modals/Modal';
import Button from 'src/components/button/Button';
import { setOpenModal, setCloseModal } from 'src/core/store/reducers/appSlice';

function LoginActonsMobile() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onCloceModal = () => {
        dispatch(setCloseModal(modalTypes.LOGIN_ACTION_MOBILE));
    };
    const openRegister = useCallback(() => {
        const modal = {
            type: modalTypes.REGISTER,
        };
        onCloceModal();
        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    const openLogin = useCallback(() => {
        const modal = {
            type: modalTypes.LOGIN,
        };

        onCloceModal();
        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    return (
        <div className="LoginActonsMobile">
            <div className="LoginActonsMobile-xmark" onClick={onCloceModal}>
                ✕
            </div>
            <div className="LoginActonsMobile-content">
                <span className="LoginActonsMobile-content-title">{t('signin')}</span>
                <div className="LoginActonsMobile-actions">
                    <Button title={t('login')} primary className="LoginActonsMobile-content-button" onClick={openLogin} />
                    <Button title={t('register')} primary className="LoginActonsMobile-content-button" onClick={openRegister} />
                </div>
            </div>
        </div>
    );
}

export default LoginActonsMobile;
