import './account.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Upload } from '../../../assets/images/upload.svg';
import photo_profile from 'src/assets/images/user.svg';
import arrowImg from 'src/assets/images/arrow.svg';
import { setOpenModal } from 'src/core/store/reducers/appSlice';
import { countryCode } from 'src/common/countries';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModal } from 'src/core/store/reducers/appSlice';
import Input from 'src/components/inputV2/input';
import OutlinedDropdown from 'src/components/outlinedDropdownV2/OutlinedDropdown';
import Button from 'src/components/buttonV2/Button';
import { getDays, getYears, getMount } from 'src/utils/utils';
import { factoryShowUploaded, factoryVerificationTitle } from '../../../utils/helpers';
import { tabTypes } from '../Profile';
import { api } from 'src/api/api';
import { useEffect, useMemo, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import parsePhoneNumberFromString, { getCountryCallingCode } from 'libphonenumber-js';
import { monthsDays } from '../../../utils/utils';
import { validatePhoneNumber, validationEmail, validationNameOrSecondName } from './validation';
import * as i18nIsoCountries from 'i18n-iso-countries';
import { setUserUpdate } from '../../../core/store/reducers/userSlice';
import moment from 'moment';
import { genderOptions } from '../../../utils/data';
import { useNavigate } from 'react-router-dom';
import { UserGenderEnum } from '../../../common/provider-common-type-util/dictionary/user';
import { DocumentStatusEnum } from '../../../common/provider-common-type-util/dictionary/document';

function Account() {
    const user = useSelector((state) => state.user.user);
    const isMobile = window.innerWidth <= 662;
    const [showEditEmail, setShowEditEmail] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingGetCode, setIsLoadingGetCode] = useState(false);
    const [countryCodes, setCountryCodes] = useState([]);
    const [codeChangeEmail, setCodeChangeEmail] = useState('');
    const [stepChangeEmail, setStepChangeEmail] = useState(1);
    const [stepChangePhone, setStepChangePhone] = useState(1);
    const [codeChangePhone, setCodeChangePhone] = useState('');
    const [showEditPhone, setShowEditPhone] = useState(false);
    const [registerOption, setRegisterOption] = useState({
        name: '',
        secondName: '',
        email: '',
        phone: '',
        password: '',
        passwordSign: '',
        day: null,
        month: null,
        year: null,
        codeCountry: null,
        gender: UserGenderEnum.USER_GENDER_MALE,
        country: '',
        city: '',
    });

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const lang = localStorage.getItem('locale');

    const loadData = async () => {
        const countryPhoneCodes = countryCode.map((item) => {
            const code = `+${getCountryCallingCode(item)}`;
            return {
                label: code,
                value: code,
                country: item,
            };
        });

        setCountryCodes(countryPhoneCodes);
    };

    const getuserInfo = (user) => {
        const languages = lang ? lang : 'en';
        i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${languages}.json`));
        const parsedPhoneNumber = user.phones.length !== 0 && parsePhoneNumberFromString(user.phones[0].payload);
        const day = user.birth
            ? { label: user.birth.split('-')[2].split('T')[0], value: user.birth.split('-')[2].split('T')[0] }
            : null;
        const month = user.birth ? { label: user.birth.split('-')[1], value: user.birth.split('-')[1] } : null;
        const year = user.birth ? { label: user.birth.split('-')[0], value: user.birth.split('-')[0] } : null;
        const country = user.country ? { label: i18nIsoCountries.getName(user.country, languages), value: user.country } : null;

        const userInfo = {
            name: user.name ? user.name : '',
            secondName: user.secondName ? user.secondName : '',
            email: user.emails.length !== 0 ? user.emails[0].payload : '',
            phone: parsedPhoneNumber ? parsedPhoneNumber.nationalNumber : '',
            day: day,
            month: month,
            year: year,
            codeCountry: parsedPhoneNumber
                ? {
                      label: `+${parsedPhoneNumber.countryCallingCode}`,
                      value: `+${parsedPhoneNumber.countryCallingCode}`,
                      country: parsedPhoneNumber.country,
                  }
                : null,
            gender: user.gender ? user.gender : UserGenderEnum.USER_GENDER_MALE,
            country: country,
            city: '',
        };

        setRegisterOption(userInfo);
    };

    useEffect(() => {
        getuserInfo(user);
        loadData();
    }, []);

    const days = useMemo(() => {
        return getDays;
    }, []);

    const mounths = useMemo(() => {
        return getMount;
    }, []);

    const years = useMemo(() => {
        return getYears;
    }, []);

    const handleChangeInput = (e) => {
        let value = e.target.value;
        const key = e.target.id;
        if (key === 'login') {
            value = value.toLowerCase();
        }
        if (key === 'email') {
            setShowEditEmail(false);
            setStepChangeEmail(1);
            setCodeChangeEmail('');
            setShowEditEmail(true);
        }

        if (key === 'phone') {
            setShowEditPhone(false);
            setStepChangePhone(1);
            setCodeChangePhone('');
            setShowEditPhone(true);
        }
        setRegisterOption({ ...registerOption, [key]: value });
    };
    const handleDayChange = (selectedDay) => {
        setRegisterOption({ ...registerOption, day: selectedDay });
    };

    const handleMonthChange = (selectedMonth) => {
        if (registerOption.day !== null) {
            if (registerOption.day.value > monthsDays[selectedMonth.value]) {
                setRegisterOption({
                    ...registerOption,
                    day: { label: monthsDays[selectedMonth.value], value: monthsDays[selectedMonth.value] },
                    month: selectedMonth,
                });
                return;
            }
        }
        setRegisterOption({ ...registerOption, month: selectedMonth });
    };

    const handleYearChange = (selectedYear) => {
        setRegisterOption({ ...registerOption, year: selectedYear });
    };

    const handleYearCode = (selectedCode) => {
        setRegisterOption({ ...registerOption, codeCountry: selectedCode });
    };

    const handleChangeCountry = (selectedCountry) => {
        if (user.phones.length === 0) {
            const phoneCode = `+${getCountryCallingCode(selectedCountry.value)}`;
            setRegisterOption({
                ...registerOption,
                country: selectedCountry,
                codeCountry: { label: phoneCode, value: phoneCode, country: selectedCountry.value },
            });
            return;
        }

        setRegisterOption({
            ...registerOption,
            country: selectedCountry,
        });
    };

    const getCountryNamesByCodes = () => {
        const languages = lang ? lang : 'en';
        i18nIsoCountries.registerLocale(require(`i18n-iso-countries/langs/${languages}.json`));
        const countries = countryCode.map((code) => {
            const translatedName = i18nIsoCountries.getName(code, languages);
            return {
                label: translatedName || 'Unknown',
                value: code,
            };
        });
        return countries;
    };

    const handleClickButtonRegister = async () => {
        setIsLoading(true);
        const { day, month, year, codeCountry, phone, country, email, ...remainingOptions } = registerOption;
        const newDate = day && month && year ? `${day.value} ${month.value} ${year.value}` : null;
        const momentDate = newDate ? moment(newDate, 'DD MM YYYY') : '';
        const formattedDate = newDate ? momentDate.format('YYYY-MM-DD') : '';

        const options = {
            ...remainingOptions,
            birth: formattedDate,
            country: country ? country?.value : null,
        };

        for (const key in options) {
            if (options[key] === '') {
                delete options[key];
            }
        }
        const resp = await api.post('/user/update', options);
        if (resp.data) {
            setIsLoading(false);
            await dispatch(setUserUpdate({ ...resp.data, ...user }));
            getuserInfo({ ...resp.data, ...user });
            NotificationManager.success(`${t('register_success')}`, '', 3500);
        } else {
            setIsLoading(false);
        }
    };

    const handleGenderChange = (gender) => {
        setRegisterOption({ ...registerOption, gender: gender.value });
    };

    const getOptionsDays = () => {
        if (registerOption.month !== null) {
            return days.slice(0, monthsDays[registerOption.month.value]);
        }

        return days;
    };

    const openModal = () => {
        if (!factoryShowUploaded(user.verification)) return;
        dispatch(setOpenModal({ modal: { type: tabTypes.UPLOAD_DOCS } }));
    };

    const factoryVerificationStyle = (verification) => {
        switch (verification) {
            case DocumentStatusEnum.DOCUMENT_STATUS_APPROVED:
                return `verification_approve`;
            case DocumentStatusEnum.DOCUMENT_STATUS_CREATED:
                return 'verification_created';
            case DocumentStatusEnum.DOCUMENT_STATUS_REJECTED:
                return 'verification_rejected';
            default:
                return 'verification_not_created';
        }
    };

    const handleChangeCodeEmail = (e) => {
        setCodeChangeEmail(e.target.value);
    };

    const factoryTitleButtonChangeEmail = () => {
        switch (stepChangeEmail) {
            case 1:
                return t('get_code');

            case 2:
                return t('change');
            default:
                return '';
        }
    };

    const factoryActionButtonChangeEmail = () => {
        switch (stepChangeEmail) {
            case 1:
                return getCodeForEmail;

            case 2:
                return changeEmail;
            default:
                return () => {};
        }
    };

    const isDisabledButtonChangeEmail = () => {
        if (stepChangeEmail === 2 && codeChangeEmail.length !== 8) return true;
        return false;
    };

    const isShowEditEmail = () => {
        if (
            showEditEmail &&
            typeof validationEmail(registerOption.email) !== 'string' &&
            (user.emails.length !== 0 ? user.emails[0].payload : '') !== registerOption.email
        )
            return true;
        else if (user.emails.length === 0) return true;
        return false;
    };

    const handleChangeCodePhone = (e) => {
        setCodeChangePhone(e.target.value);
    };

    const isDisabledButtonChangePhone = () => {
        if (stepChangePhone === 2 && codeChangePhone.length !== 8) return true;
        return false;
    };

    const isShowEditPhone = () => {
        if (
            showEditPhone &&
            typeof validatePhoneNumber(
                (registerOption.codeCountry !== null ? registerOption.codeCountry.value : '') + registerOption.phone,
                registerOption.codeCountry?.country,
            ) !== 'string' &&
            user.phones[0].payload !==
                (registerOption.codeCountry !== null ? registerOption.codeCountry.value : '') + registerOption.phone
        )
            return true;
        else if (user.phones.length === 0) return true;
        else if (user.phones[0].status === 0) return true;
        return false;
    };

    const getCodeForPhone = async () => {
        if (
            typeof validatePhoneNumber(
                (registerOption.codeCountry !== null ? registerOption.codeCountry.value : '') + registerOption.phone,
                registerOption.codeCountry?.country,
            ) === 'string'
        )
            return;
        const phone = encodeURIComponent(`${registerOption.codeCountry.value}${registerOption.phone}`);

        const resp = await api.get(`/user/confirm/code?phone=${phone}`);
        if (resp.data) {
            setIsLoadingGetCode(false);
            setStepChangePhone(2);
            // TODO View code
            NotificationManager.warning(`${t('code')}: ${resp.data.codePayload}`, '', 20000);
        } else {
            setIsLoadingGetCode(false);
            switch (resp?.error?.response?.status) {
                case 403:
                case 404:
                case 405:
                case 406:
                    setStepChangePhone(2);
                    break;
                default:
                    break;
            }
        }
    };

    const getCodeForEmail = async () => {
        if (typeof validationEmail(registerOption.email) === 'string' || registerOption.email.length === 0) return;
        setIsLoadingGetCode(true);
        const email = registerOption.email;

        const resp = await api.get(`/user/confirm/code?email=${email}`);
        if (resp.data) {
            setIsLoadingGetCode(false);
            setStepChangeEmail(2);
            // TODO View code
            NotificationManager.warning(`${t('code')}: ${resp.data.codePayload}`, '', 20000);
        } else {
            setIsLoadingGetCode(false);
            switch (resp?.error?.response?.status) {
                case 403:
                case 404:
                case 405:
                case 406:
                    setStepChangeEmail(2);
                    break;

                default:
                    break;
            }
        }
    };

    const changeEmail = async () => {
        setIsLoadingGetCode(true);

        const options = {
            email: registerOption.email,
            codePayload: codeChangeEmail,
        };

        const resp = await api.post(`/user/confirm`, options);

        if (resp.data) {
            setIsLoadingGetCode(false);
            setShowEditEmail(false);
            setStepChangeEmail(1);
            setCodeChangeEmail('');
        } else {
            setIsLoadingGetCode(false);
        }
    };

    const changePhone = async () => {
        setIsLoadingGetCode(true);

        const phone = `${registerOption.codeCountry.value}${registerOption.phone}`;

        const options = {
            phone: phone,
            codePayload: codeChangePhone,
        };

        const resp = await api.post(`/user/confirm`, options);

        if (resp.data) {
            setIsLoadingGetCode(false);
            setShowEditPhone(false);
            setStepChangePhone(1);
            setCodeChangePhone('');
        } else {
            setIsLoadingGetCode(false);
        }
    };

    const factoryActionButtonChangePhone = () => {
        switch (stepChangePhone) {
            case 1:
                return getCodeForPhone;

            case 2:
                return changePhone;
            default:
                return () => {};
        }
    };

    const factoryTitleButtonChangePhone = () => {
        switch (stepChangePhone) {
            case 1:
                return t('get_code');

            case 2:
                return t('change');
            default:
                return '';
        }
    };

    const handleCloseModal = () => {
        dispatch(setCloseModal(modalTypes.ACCOUNT));
        dispatch(setCloseModal(modalTypes.PROFILE));
        if (window.history.state && window.history.state.idx > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="Account">
            <div className={factoryVerificationStyle(user.verification)} onClick={openModal}>
                <span>{factoryVerificationTitle(user.verification)}</span>
                {factoryShowUploaded(user.verification) && (
                    <div className="Account-upload">
                        <Upload />
                    </div>
                )}
            </div>
            <div className="UserInfo">
                <div className="UserInfo-mobile-header" onClick={() => dispatch(setCloseModal(modalTypes.ACCOUNT))}>
                    <div className="UserInfo-mobile-goback">
                        <img src={arrowImg} alt="arrow" className="UserInfo-mobile-arrow" />
                        <span>{t('back')}</span>
                    </div>
                    <div className="UserInfo-mobile-header-title">
                        <img src={photo_profile} alt="account" />
                        <span>{t('account')}</span>
                    </div>
                </div>

                <div className="UserInfo-content-step-1">
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={t('user_name_input')}
                            placeholder={t('enter_name')}
                            value={registerOption.name}
                            id="name"
                            onChange={handleChangeInput}
                            error={validationNameOrSecondName(registerOption.name)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={t('user_surname_input')}
                            placeholder={t('enter_surname')}
                            value={registerOption.secondName}
                            id="secondName"
                            onChange={handleChangeInput}
                            error={validationNameOrSecondName(registerOption.secondName)}
                        />
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={t('e_mail')}
                            placeholder={t('enter_e_mail')}
                            secondLabel={
                                user.emails?.length !== 0
                                    ? user.emails[0].status === 0
                                        ? t('not_verified')
                                        : t('verified')
                                    : t('not_verified')
                            }
                            value={registerOption.email}
                            id="email"
                            onChange={handleChangeInput}
                            error={validationEmail(registerOption.email)}
                        />
                        {isShowEditEmail() && isMobile && (
                            <div className="UserInfo-content-step-1-code">
                                <Input
                                    className="UserInfo-content-step-1-code-input"
                                    hideLabel
                                    placeholder={t('code')}
                                    value={codeChangeEmail}
                                    id="code"
                                    onChange={handleChangeCodeEmail}
                                />
                                <Button
                                    className="UserInfo-content-step-1-code-button"
                                    title={factoryTitleButtonChangeEmail()}
                                    primary
                                    onClick={factoryActionButtonChangeEmail()}
                                    loading={isLoadingGetCode}
                                    disabled={isDisabledButtonChangeEmail()}
                                />
                            </div>
                        )}
                        <div className="UserInfo-content-step-1-country">
                            <span className="UserInfo-content-step-1-country-title">{t('country')}</span>
                            <OutlinedDropdown
                                className="UserInfo-content-step-1-country-dropdown"
                                placeholder={t('select_country')}
                                options={getCountryNamesByCodes()}
                                onSelect={handleChangeCountry}
                                defaultSelected={registerOption.country}
                            />
                        </div>
                    </div>
                    {isShowEditEmail() && !isMobile && (
                        <div className="UserInfo-content-step-1-code">
                            <Input
                                className="UserInfo-content-step-1-code-input"
                                hideLabel
                                placeholder={t('code')}
                                value={codeChangeEmail}
                                id="code"
                                onChange={handleChangeCodeEmail}
                            />
                            <Button
                                className="UserInfo-content-step-1-code-button"
                                title={factoryTitleButtonChangeEmail()}
                                primary
                                onClick={factoryActionButtonChangeEmail()}
                                loading={isLoadingGetCode}
                                disabled={isDisabledButtonChangeEmail()}
                            />
                        </div>
                    )}
                    <div className="UserInfo-content-step-1-inputs">
                        <Input
                            className="UserInfo-content-step-1-input"
                            label={t('select_city')}
                            placeholder={t('select_city')}
                            value={registerOption.city}
                            id="id-city"
                            onChange={handleChangeInput}
                        />
                        <div className="UserInfo-content-step-1-phone-wrapper">
                            <span className="UserInfo-content-step-1-phone-title">
                                {t('phone')}
                                <span className="UserInfo-content-step-1-phone-second-title">
                                    {user.phones.length !== 0
                                        ? user.phones[0].status === 1
                                            ? t('not_verified')
                                            : t('verified')
                                        : ''}
                                </span>
                            </span>
                            <div className="UserInfo-content-step-1-phone">
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-phone-dropdown"
                                    placeholder={'+355'}
                                    options={countryCodes}
                                    onSelect={handleYearCode}
                                    defaultSelected={registerOption.codeCountry}
                                />
                                <Input
                                    className="UserInfo-content-step-1-phone-input"
                                    placeholder={'97404013'}
                                    hideLabel
                                    value={registerOption.phone}
                                    id="phone"
                                    onChange={handleChangeInput}
                                    error={validatePhoneNumber(
                                        (registerOption.codeCountry !== null ? registerOption.codeCountry.value : '') +
                                            registerOption.phone,
                                        registerOption.codeCountry?.country,
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                    {isShowEditPhone() && (
                        <div className="UserInfo-content-step-1-code-phone">
                            <Input
                                className="UserInfo-content-step-1-code-phone-input"
                                hideLabel
                                placeholder={t('code')}
                                value={codeChangePhone}
                                id="code"
                                onChange={handleChangeCodePhone}
                            />
                            <Button
                                className="UserInfo-content-step-1-code-phone-button"
                                title={factoryTitleButtonChangePhone()}
                                primary
                                onClick={factoryActionButtonChangePhone()}
                                loading={isLoadingGetCode}
                                disabled={isDisabledButtonChangePhone()}
                            />
                        </div>
                    )}
                    <div className="UserInfo-content-step-1-personal-info">
                        <div className="UserInfo-content-step-1-birhtday">
                            <span className="UserInfo-content-step-1-birhtday-title">{t('birthaday_date')}</span>
                            <div className="UserInfo-content-step-1-birhtday-dropdowns">
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={t('day')}
                                    options={getOptionsDays()}
                                    onSelect={handleDayChange}
                                    defaultSelected={registerOption.day}
                                />
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={t('month')}
                                    options={mounths}
                                    onSelect={handleMonthChange}
                                    defaultSelected={registerOption.month}
                                />
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-birhtday-dropdown"
                                    placeholder={t('year')}
                                    options={years}
                                    onSelect={handleYearChange}
                                    defaultSelected={registerOption.year}
                                />
                            </div>
                        </div>
                        <div className="UserInfo-content-step-1-gender-info">
                            <div className="UserInfo-content-step-1-gender">
                                <span className="UserInfo-content-step-1-gender-title">{t('gender')}</span>
                                <OutlinedDropdown
                                    className="UserInfo-content-step-1-gender-dropdown"
                                    placeholder={t('gender')}
                                    options={genderOptions}
                                    onSelect={handleGenderChange}
                                    defaultSelected={{
                                        label: registerOption.gender === 1 ? t('male') : t('female'),
                                        value: registerOption.gender,
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="UserInfo-content-step-1-buttons">
                        <Button className="UserInfo-content-step-2-prev" title={t('cancel')} onClick={handleCloseModal} />
                        <Button
                            className="UserInfo-content-step-2-prev"
                            title={t('save')}
                            primary
                            onClick={handleClickButtonRegister}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Account;
