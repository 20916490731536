import { configureStore } from '@reduxjs/toolkit';
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';
import { Action } from 'redux';

import appSlice from './reducers/appSlice';
import balanceSlice from './reducers/balanceSlice';
import gamesSlice from './reducers/gamesSlice';
import sportsSlice from './reducers/sportSlice';
import userSlice from './reducers/userSlice';

const store = configureStore({
    reducer: {
        user: userSlice,
        app: appSlice,
        balance: balanceSlice,
        games: gamesSlice,
        sports: sportsSlice,
    },
});

export type RootState = ReturnType<typeof store.getState>;

export const appDispatch = (action: Action): void => {
    store.dispatch(action);
};
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;

export default store;
