import './realTimeClock.scss';
import React, { useState, useEffect, useRef } from 'react';

function RealTimeClock() {
    const [currentTime, setCurrentTime] = useState(new Date());
    const timezone = useRef(new Date().getTimezoneOffset() / -60);

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, []);

    const hours = currentTime.getHours();
    const minutes = currentTime.getMinutes();
    const seconds = currentTime.getSeconds();

    return (
        <div className="RealTimeClock">
            <div className="RealTimeClock-time">
                <span>{hours < 10 ? `0${hours}` : hours}</span>:<span>{minutes < 10 ? `0${minutes}` : minutes}</span>:
                <span>{seconds < 10 ? `0${seconds}` : seconds}</span>
            </div>
        </div>
    );
}

export default RealTimeClock;
