import { isValidPhoneNumber } from 'libphonenumber-js';
import { EnumValidate } from '../../common/enum';
import { t } from 'i18next';

export const validatePhoneNumber = (value) => {
    if (value.length === 4) return null;

    try {
        if (isValidPhoneNumber(value, 'he')) {
            return null;
        } else {
            return t('validate_phone');
        }
    } catch (error) {
        return t('validate_phone');
    }
};

export const validationLogin = (value) => {
    if (value.length === 0) return null;
    else if (value.length < EnumValidate.VALIDATE_LOGIN_LENGTH_MIN || value.length > EnumValidate.VALIDATE_LOGIN_LENGTH_MAX)
        return t('validate_longer_4');
    return null;
};

export const validationEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (value.length === 0) return null;
    if (!emailRegex.test(value)) return t('validate_email');
    return null;
};

export const validationPassword = (value) => {
    if (value.length === 0) return null;
    else if (value.length < EnumValidate.VALIDATE_PASSWORD_LENGTH_MIN || value.length > EnumValidate.VALIDATE_PASSWORD_LENGTH_MAX)
        return t('validate_password');
    return null;
};
