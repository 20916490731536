import classNames from 'classnames';
import { ReactComponent as Loading } from 'src/assets/images/loading.svg';
import './button.scss';

function Button({ title, primary, className, icon, onClick, disabled = false, loading = false }) {
    const onCLickHandler = () => {
        if (onClick && !disabled && !loading) {
            onClick();
        }
    };
    return (
        <div
            className={classNames('ButtonV2-main', className, {
                ['primary']: primary,
                ['loading']: loading,
                ['disabled']: disabled,
            })}
            onClick={onCLickHandler}
        >
            {icon}
            {title && <span className={loading ? 'loading-title' : ''}>{title}</span>}
            {loading && (
                <div className="loading-svg">
                    <Loading />
                </div>
            )}
        </div>
    );
}

export default Button;
