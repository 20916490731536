/* eslint-disable no-unused-vars */
export class UserDictionary {
  // User status
  static USER_STATUS_ACTIVE: number = 1;
  static USER_STATUS_BANNED: number = 2;
  static USER_STATUS_DELETED: number = 3;

  static USER_GENDER_MALE: number = 1;
  static USER_GENDER_FEMALE: number = 2;
}

export enum UserStatusEnum {
  USER_STATUS_ACTIVE = UserDictionary.USER_STATUS_ACTIVE,
  USER_STATUS_BANNED = UserDictionary.USER_STATUS_BANNED,
  USER_STATUS_DELETED = UserDictionary.USER_STATUS_DELETED
}

export enum UserGenderEnum {
  USER_GENDER_MALE = UserDictionary.USER_GENDER_MALE,
  USER_GENDER_FEMALE = UserDictionary.USER_GENDER_FEMALE
}
