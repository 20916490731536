// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from 'src/assets/translations/en.json';
import heTranslations from 'src/assets/translations/he.json';
import ruTranslations from 'src/assets/translations/ru.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslations,
        },
        he: {
            translation: heTranslations,
        },
        ru: {
            translation: ruTranslations,
        },
    },
    // eslint-disable-next-line no-undef
    lng: localStorage.getItem('locale') || 'en',
    fallbackLng: 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
