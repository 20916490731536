import axios from 'axios';
import { logoutFromAccount } from 'src/core/engines/AppEngine';
import { getAuthToken } from 'src/utils/cookies';
import { checkPermission } from 'src/utils/helpers';
import packageJson from '../../package.json';
import { showErrorFactory } from '../utils/helperError';

console.log('version:', packageJson?.version);

const lang = localStorage.getItem('locale');

export const api = axios.create({
    baseURL: '',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded', 'Accept-Language': lang ? lang : 'en' },
});

api.interceptors.request.use(
    (config) => {
        const token = getAuthToken();
        if (token) {
            const validateToken = checkPermission(token);
            if (!validateToken) {
                logoutFromAccount();
                return;
            }
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => error,
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.log(error);
        if (error?.config?.url === '/document/status' && error.response.status === 404) {
            console.log(error);
        } else {
            showErrorFactory(error);
        }
        return { error };
    },
);
