// TODO: rm for prod
// 5.6 Game Type
export enum GameTypeEnum {
  "Slot" = 1,
  "Live Casino" = 2,
  "Sport Book" = 3,
  "Virtual Sport" = 4,
  "Lottery" = 5,
  "Qipai" = 6,
  "P2P" = 7,
  "Fishing" = 8,
  "Others" = 9,
  "Cock Fighting" = 10,
  "Bonus" = 11,
  "Jackpot" = 12,
  "ESport" = 13
}
