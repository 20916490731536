import classNames from 'classnames';
import React, { FC } from 'react';
import './radioButton.scss';

interface RadioButtonProps {
    className?: string;
    value: boolean;
    onChange: () => void;
    hideLabel?: boolean;
    label?: string;
    classNameButton?: string;
}

const RadioButton: FC<RadioButtonProps> = ({
    className,
    value = false,
    onChange = () => '',
    label = '',
    hideLabel = false,
    classNameButton = '',
}): JSX.Element => (
    <div className={classNames('RadioButton', className, { 'RadioButton-selected': value })}>
        <div onClick={onChange} className={classNames('RadioButton-button', classNameButton)}>
            {value && <div className="RadioButton-dot" />}
        </div>
        {!hideLabel && label && <span className="RadioButton-label">{label}</span>}
    </div>
);

export default RadioButton;
