import React, { FC } from 'react';
import './exit.scss';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg';
import Button from 'src/components/buttonV2/Button';
import { modalTypes } from 'src/components/modals/Modal';
import { logoutFromAccount } from 'src/core/engines/AppEngine';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';

const Exit: FC = (): JSX.Element => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const closeModal = () => {
        appDispatch(setCloseModalTTT(String(modalTypes.EXIT)));
    };

    const onExit = () => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        logoutFromAccount();
        closeModal();
        navigate('/');
    };

    return (
        <div className="Exit">
            <div className="Exit-xmark" onClick={closeModal}>
                <CloseIcon />
            </div>
            <div className="Exit-content">
                <div className="Exit-content-title">{t('are_you_sure_exit')} </div>
                <div className="Exit-content-buttons">
                    <Button className="Exit-content-button" title={t('cancel')} onClick={closeModal} />
                    <Button className="Exit-content-button" title={t('exit')} primary onClick={onExit} />
                </div>
            </div>
        </div>
    );
};

export default Exit;
