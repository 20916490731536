/* eslint-disable */
import React, { FC, useState } from 'react';
import { ReactComponent as Arrow } from 'src/assets/images/icon-accordion.svg';
import { ReactComponent as Football } from 'src/assets/images/sports/football.svg';
import { ReactComponent as Time } from 'src/assets/images/time.svg';
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg';
import Button from 'src/components/buttonV2/Button';

import './Betting.scss';

interface IProps {}

const Betting: FC<IProps> = () => {
    const [show, setShow] = useState(false);
    return (
        <div className="Betting">
            <div className="Betting-header">
                <div className="Betting-header-title">
                    <span>BET SLIP</span>
                    <span>1</span>
                </div>
                <div className="Betting-header-arrow" onClick={() => setShow(!show)}>
                    <Arrow />
                </div>
            </div>
            {show && (
                <div className="Betting-content">
                    <div className="Betting-content-tabs">
                        <span>SINGLE</span>
                        <span>MULTI</span>
                        <span>SYSTEM</span>
                    </div>
                    <div className="Betting-content-list">
                        <div className="Betting-content-list-item">
                            <div className="Betting-content-list-item-icon">
                                <Football />
                            </div>
                            <div className="Betting-content-list-item-content">
                                <span>Argentina (3x3)</span>
                                <div className="Betting-content-list-item-content-score">
                                    <span>1</span>
                                    <span>:</span>
                                    <span>3</span>
                                </div>

                                <span>Germany (3x3)</span>
                                <div className="Betting-content-list-item-content-action">
                                    <CloseIcon className="Betting-content-list-item-content-action-icon" />
                                </div>
                            </div>
                        </div>
                        <div className="Betting-content-list-item">
                            <div className="Betting-content-list-item-icon">
                                <Football />
                            </div>
                            <div className="Betting-content-list-item-content">
                                <span>Argentina (3x3)</span>
                                <div className="Betting-content-list-item-content-score">
                                    <span>1</span>
                                    <span>:</span>
                                    <span>3</span>
                                </div>

                                <span>Germany (3x3)</span>
                                <div className="Betting-content-list-item-content-action">
                                    <CloseIcon className="Betting-content-list-item-content-action-icon" />
                                </div>
                            </div>
                        </div>
                        <div className="Betting-content-list-footer">
                            <div className="Betting-content-list-footer-icon">
                                <Time />
                            </div>
                            <div className="Betting-content-list-footer-content">
                                <div className="Betting-content-list-footer-content-title">
                                    <span>Title:</span>
                                    <span>Under (14)</span>
                                </div>
                                <div className="Betting-content-list-footer-content-count">
                                    <span>1.66</span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="Betting-content-form">
                        <div className="Betting-content-form-input">
                            <input type="text" placeholder="Stake" />
                        </div>
                        <div className="Betting-content-form-info">
                            <span>TOTAL WIN</span>
                            <div className="Betting-content-form-info-count">
                                <span>0</span>
                                <span>USD</span>
                            </div>
                        </div>
                    </div>
                    <div className="Betting-content-form-button">
                        <Button primary title="PLACE BET" />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Betting;
