/* eslint-disable no-unused-vars */

export class BalanceDictionary {
  // Balance types
  // System balance
  static BALANCE_TYPE_TRANSFER: number = 1; // +, 2targets
  static BALANCE_TYPE_TAKE: number = 2; // -, 2targets
  static BALANCE_TYPE_DEPOSIT: number = 3; // deposit +
  static BALANCE_TYPE_OUT: number = 4; // out -
  static BALANCE_TYPE_REDUCE: number = 5; // game bet / ticket, -
  static BALANCE_TYPE_ADD: number = 6; // game won, +
  static BALANCE_TYPE_REFUND: number = 7; // failing game, partial chance won or game result is not valid +
  static BALANCE_TYPE_SCEDUAL_REDUCE: number = 8; // zero User.balance, -, 2targets
  static BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE: number = 9; // zero User.scedualPaymentBalance, -, 2targets
  // static BALANCE_TYPE_SCEDUAL_PAYMENT: number = 10; // sced payment +
  static BALANCE_TYPE_INFO: number = 11;
  // Providers balance
  static BALANCE_TYPE_RACING_DEPOSIT: number = 30; // 0, +
  static BALANCE_TYPE_RACING_WITHDRAW: number = 31; // 1, -
  // live-casino type
  static BALANCE_TYPE_CASINO_DEBIT: number = 40; // -
  static BALANCE_TYPE_CASINO_CREDIT: number = 41; // +
  static BALANCE_TYPE_CASINO_ROLLBACK: number = 42; // +-
  // sport type
  static BALANCE_TYPE_SPORT_DEBIT: number = 50; // -
  static BALANCE_TYPE_SPORT_CREDIT: number = 51; // +
  static BALANCE_TYPE_SPORT_ROLLBACK: number = 52; // +-

  // live-casino providerType
  static BALANCE_TYPE_CASINO_PLACE_BET: number = 400; // 2.2 placeBet -
  static BALANCE_TYPE_CASINO_GAME_RESULT_WIN: number = 401; // 2.3 Game Result (default: win +; direct resettlement -) +
  static BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT: number = 402; // -
  // 2.4 Rollback (default: cancel win -; Bet Adjustment / bet correct +, Reverse Bet / cancel Bet +-) -
  static BALANCE_TYPE_CASINO_ROLLBACK_WIN: number = 403;
  static BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT: number = 404; // +
  static BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE: number = 405; // +-, +
  static BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE: number = 406; // +-, -
  static BALANCE_TYPE_CASINO_CANCEL_BET: number = 407; // 2.5 cancelBet +
  static BALANCE_TYPE_CASINO_BONUS: number = 408; // 2.6 bonus +
  static BALANCE_TYPE_CASINO_JACKPOT: number = 409; // 2.7 jackpot +
  static BALANCE_TYPE_CASINO_BUY_IN: number = 410; // 2.9 buyIn -
  static BALANCE_TYPE_CASINO_BUY_OUT: number = 411; // 2.10 buyOut +
  static BALANCE_TYPE_CASINO_PUSH_BET: number = 412; // 2.11
  // sport providerType
  static BALANCE_PROVIDER_TYPE_SPORT_DEBIT: number = 500;
  static BALANCE_PROVIDER_TYPE_SPORT_CREDIT: number = 501;
  static BALANCE_PROVIDER_TYPE_SPORT_ROLLBACK: number = 502;

  // Balance-out status
  static BALANCE_OUT_STATUS_CREATED: number = 1;
  static BALANCE_OUT_STATUS_REJECTED: number = 2;
  static BALANCE_OUT_STATUS_APPROVED: number = 3;
  static BALANCE_OUT_STATUS_FINISHED: number = 4;
}

export enum BalanceTypeEnum {
  BALANCE_TYPE_TRANSFER = BalanceDictionary.BALANCE_TYPE_TRANSFER,
  BALANCE_TYPE_TAKE = BalanceDictionary.BALANCE_TYPE_TAKE,
  BALANCE_TYPE_DEPOSIT = BalanceDictionary.BALANCE_TYPE_DEPOSIT,
  BALANCE_TYPE_OUT = BalanceDictionary.BALANCE_TYPE_OUT,
  BALANCE_TYPE_REDUCE = BalanceDictionary.BALANCE_TYPE_REDUCE,
  BALANCE_TYPE_ADD = BalanceDictionary.BALANCE_TYPE_ADD,
  BALANCE_TYPE_REFUND = BalanceDictionary.BALANCE_TYPE_REFUND,
  BALANCE_TYPE_SCEDUAL_REDUCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE,
  BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE,
  BALANCE_TYPE_INFO = BalanceDictionary.BALANCE_TYPE_INFO,
  BALANCE_TYPE_RACING_DEPOSIT = BalanceDictionary.BALANCE_TYPE_RACING_DEPOSIT,
  BALANCE_TYPE_RACING_WITHDRAW = BalanceDictionary.BALANCE_TYPE_RACING_WITHDRAW,
  BALANCE_TYPE_CASINO_DEBIT = BalanceDictionary.BALANCE_TYPE_CASINO_DEBIT,
  BALANCE_TYPE_CASINO_CREDIT = BalanceDictionary.BALANCE_TYPE_CASINO_CREDIT,
  BALANCE_TYPE_CASINO_ROLLBACK = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK,
  BALANCE_TYPE_SPORT_DEBIT = BalanceDictionary.BALANCE_TYPE_SPORT_DEBIT,
  BALANCE_TYPE_SPORT_CREDIT = BalanceDictionary.BALANCE_TYPE_SPORT_CREDIT,
  BALANCE_TYPE_SPORT_ROLLBACK = BalanceDictionary.BALANCE_TYPE_SPORT_ROLLBACK
}

export enum BalanceChanceTypeEnum {
  BALANCE_TYPE_ADD = BalanceDictionary.BALANCE_TYPE_ADD,
  BALANCE_TYPE_REFUND = BalanceDictionary.BALANCE_TYPE_REFUND
}

export enum BalanceScedualPaymentTypeEnum {
  BALANCE_TYPE_SCEDUAL_REDUCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE,
  BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE
}

export enum BalanceTypeSystemEnum {
  BALANCE_TYPE_TRANSFER = BalanceDictionary.BALANCE_TYPE_TRANSFER,
  BALANCE_TYPE_TAKE = BalanceDictionary.BALANCE_TYPE_TAKE,
  BALANCE_TYPE_DEPOSIT = BalanceDictionary.BALANCE_TYPE_DEPOSIT,
  BALANCE_TYPE_OUT = BalanceDictionary.BALANCE_TYPE_OUT,
  BALANCE_TYPE_REDUCE = BalanceDictionary.BALANCE_TYPE_REDUCE,
  BALANCE_TYPE_ADD = BalanceDictionary.BALANCE_TYPE_ADD,
  BALANCE_TYPE_REFUND = BalanceDictionary.BALANCE_TYPE_REFUND,
  BALANCE_TYPE_SCEDUAL_REDUCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE,
  BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE = BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE
}

export enum BalanceTypeBettingEnum {
  BALANCE_TYPE_REDUCE = BalanceDictionary.BALANCE_TYPE_REDUCE,
  BALANCE_TYPE_ADD = BalanceDictionary.BALANCE_TYPE_ADD,
  BALANCE_TYPE_REFUND = BalanceDictionary.BALANCE_TYPE_REFUND
}

export enum BalanceTypeProviderEnum {
  BALANCE_TYPE_RACING_DEPOSIT = BalanceDictionary.BALANCE_TYPE_RACING_DEPOSIT,
  BALANCE_TYPE_RACING_WITHDRAW = BalanceDictionary.BALANCE_TYPE_RACING_WITHDRAW,
  BALANCE_TYPE_CASINO_DEBIT = BalanceDictionary.BALANCE_TYPE_CASINO_DEBIT,
  BALANCE_TYPE_CASINO_CREDIT = BalanceDictionary.BALANCE_TYPE_CASINO_CREDIT,
  BALANCE_TYPE_CASINO_ROLLBACK = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK,
  BALANCE_TYPE_SPORT_DEBIT = BalanceDictionary.BALANCE_TYPE_SPORT_DEBIT,
  BALANCE_TYPE_SPORT_CREDIT = BalanceDictionary.BALANCE_TYPE_SPORT_CREDIT,
  BALANCE_TYPE_SPORT_ROLLBACK = BalanceDictionary.BALANCE_TYPE_SPORT_ROLLBACK
}

export const BalanceTypePositiveConst: BalanceTypeEnum[] = [
  BalanceDictionary.BALANCE_TYPE_TRANSFER,
  BalanceDictionary.BALANCE_TYPE_DEPOSIT,
  BalanceDictionary.BALANCE_TYPE_ADD,
  BalanceDictionary.BALANCE_TYPE_REFUND,
  BalanceDictionary.BALANCE_TYPE_RACING_DEPOSIT,
  BalanceDictionary.BALANCE_TYPE_CASINO_CREDIT,
  // BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK,
  BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_WIN,
  BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT,
  BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE,
  BalanceDictionary.BALANCE_TYPE_CASINO_CANCEL_BET,
  BalanceDictionary.BALANCE_TYPE_CASINO_BONUS,
  BalanceDictionary.BALANCE_TYPE_CASINO_JACKPOT,
  BalanceDictionary.BALANCE_TYPE_CASINO_BUY_OUT,
  BalanceDictionary.BALANCE_TYPE_SPORT_CREDIT,
  BalanceDictionary.BALANCE_TYPE_SPORT_ROLLBACK
];

export const BalanceTypeNegativeConst: BalanceTypeEnum[] = [
  BalanceDictionary.BALANCE_TYPE_TAKE,
  BalanceDictionary.BALANCE_TYPE_OUT,
  BalanceDictionary.BALANCE_TYPE_REDUCE,
  BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE,
  BalanceDictionary.BALANCE_TYPE_SCEDUAL_REDUCE_BALANCE,
  BalanceDictionary.BALANCE_TYPE_RACING_WITHDRAW,
  BalanceDictionary.BALANCE_TYPE_CASINO_DEBIT,
  BalanceDictionary.BALANCE_TYPE_CASINO_PLACE_BET,
  // BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK,
  BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT,
  BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_WIN,
  BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE,
  BalanceDictionary.BALANCE_TYPE_CASINO_BUY_IN,
  BalanceDictionary.BALANCE_TYPE_SPORT_DEBIT
];

export enum BalanceProviderTypeEnum {
  BALANCE_TYPE_CASINO_PLACE_BET = BalanceDictionary.BALANCE_TYPE_CASINO_PLACE_BET,
  BALANCE_TYPE_CASINO_GAME_RESULT_WIN = BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_WIN,
  BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT = BalanceDictionary.BALANCE_TYPE_CASINO_GAME_RESULT_DIRECT_RESETTLEMENT,
  BALANCE_TYPE_CASINO_ROLLBACK_WIN = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_WIN,
  BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_ADJUSTMENT,
  BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE_POSITIVE,
  BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE = BalanceDictionary.BALANCE_TYPE_CASINO_ROLLBACK_BET_REVERSE,
  BALANCE_TYPE_CASINO_CANCEL_BET = BalanceDictionary.BALANCE_TYPE_CASINO_CANCEL_BET,
  BALANCE_TYPE_CASINO_BONUS = BalanceDictionary.BALANCE_TYPE_CASINO_BONUS,
  BALANCE_TYPE_CASINO_JACKPOT = BalanceDictionary.BALANCE_TYPE_CASINO_JACKPOT,
  BALANCE_TYPE_CASINO_BUY_IN = BalanceDictionary.BALANCE_TYPE_CASINO_BUY_IN,
  BALANCE_TYPE_CASINO_BUY_OUT = BalanceDictionary.BALANCE_TYPE_CASINO_BUY_OUT,
  BALANCE_TYPE_CASINO_PUSH_BET = BalanceDictionary.BALANCE_TYPE_CASINO_PUSH_BET,
  BALANCE_PROVIDER_TYPE_SPORT_DEBIT = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_DEBIT,
  BALANCE_PROVIDER_TYPE_SPORT_CREDIT = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_CREDIT,
  BALANCE_PROVIDER_TYPE_SPORT_ROLLBACK = BalanceDictionary.BALANCE_PROVIDER_TYPE_SPORT_ROLLBACK
}

export enum BalanceOutStatusEnum {
  BALANCE_OUT_STATUS_CREATED = BalanceDictionary.BALANCE_OUT_STATUS_CREATED,
  BALANCE_OUT_STATUS_REJECTED = BalanceDictionary.BALANCE_OUT_STATUS_REJECTED,
  BALANCE_OUT_STATUS_APPROVED = BalanceDictionary.BALANCE_OUT_STATUS_APPROVED,
  BALANCE_OUT_STATUS_FINISHED = BalanceDictionary.BALANCE_OUT_STATUS_FINISHED
}
