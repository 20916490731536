/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
import React, { FC } from 'react';
import './uploadDocs.scss';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line import/no-extraneous-dependencies
import ImageUploading from 'react-images-uploading';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api/api';
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg';
import Button from 'src/components/buttonV2/Button';
import { modalTypes } from 'src/components/modals/Modal';
import { getStatus } from 'src/core/engines/AppEngine';
// import { logoutFromAccount } from 'src/core/engines/AppEngine';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';

const UploadDocs: FC = (): JSX.Element => {
    const { t } = useTranslation();
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [images, setImages] = React.useState<any[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const maxNumber = 3;
    const DEFAULT_UPLOAD_FILETYPES: string[] = ['png', 'jpg', 'jpeg', 'ico', 'svg'];

    const closeModal = () => {
        appDispatch(setCloseModalTTT(String(modalTypes.UPLOAD_DOCS)));
    };
    const onChange = (imageList) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        setImages(imageList as any[]);
    };

    const oSave = async () => {
        setIsLoading(true);
        const formData = new FormData();

        images.forEach((file) => {
            formData.append('files', file?.file);
        });

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };

        const resp = await api.post('document/create', formData, config);

        if (resp.data) {
            setIsLoading(false);
            NotificationManager.success(t('docs_uploaded'), '', 3000);
            getStatus();
            closeModal();
        } else {
            setIsLoading(false);
        }
    };

    const onErrorUpload = (e: { maxNumber: boolean }) => {
        if (e.maxNumber) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
            NotificationManager.error(t('maximus_2_images'), '', 3000);
        }
    };

    return (
        <div className="UploadDocs">
            <div className="UploadDocs-xmark" onClick={closeModal}>
                <CloseIcon />
            </div>
            <div className="UploadDocs-header">
                <div className="UploadDocs-header-title">{t('upload_desc')}</div>
            </div>
            <div className="UploadDocs-content">
                <div>
                    <ImageUploading
                        multiple
                        value={images}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        onError={onErrorUpload}
                        acceptType={DEFAULT_UPLOAD_FILETYPES}
                    >
                        {({ imageList, onImageUpload, isDragging, dragProps }) => (
                            <div className="UploadDocs-wrapper">
                                {imageList.length < 22 && (
                                    <div
                                        className="UploadDocs-area"
                                        style={isDragging ? { color: 'white' } : undefined}
                                        onClick={onImageUpload}
                                        {...dragProps}
                                    >
                                        <span className="UploadDocs-area-title">{t('click_or_drop_here')}</span>
                                        <p>{t('or')}</p>
                                        <Button className="UploadDocs-content-button-upload" title={t('upload_photo')} primary />
                                    </div>
                                )}
                                {/* <div className="UploadDocs-image-wrapper">
                                    {imageList.map((image, index) => (
                                        <div key={index} className="UploadDocs-image-item">
                                            <img src={image.data_url} alt="" width="100" />
                                            <div onClick={() => onImageRemove(index)}>✕</div>
                                        </div>
                                    ))}
                                </div> */}
                            </div>
                        )}
                    </ImageUploading>
                </div>
                <div className="UploadDocs-content-description">
                    {t('description_upload_1')} <span>{t('description_upload_2')}</span> {t('description_upload_3')}{' '}
                    <span>{t('description_upload_4')}</span>
                </div>
                <div className="UploadDocs-content-count">
                    <span>{t('uploaded')}</span>
                    <span>{images.length}/3</span>
                </div>
                <div className="UploadDocs-content-buttons">
                    <Button className="UploadDocs-content-button" title={t('cancel')} onClick={closeModal} />
                    <Button className="UploadDocs-content-button" loading={isLoading} title={t('send')} primary onClick={oSave} />
                </div>
            </div>
        </div>
    );
};

export default UploadDocs;
