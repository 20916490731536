import { createSlice } from '@reduxjs/toolkit';

interface BalanceState {
    balance: {
        totalCount: number;
        data: {
            balances: ResourceBalance[];
        };
    };
}

interface ResourceBalance {
    amount: number;
    createDate: string;
    creatorCurrentBalance: number;
    gameProvider: string;
    id: number;
    playerCashback: null;
    providerGameId: number | null;
    providerGameName: string | null;
    providerGameType: number | null;
    providerParentTransactionId: null;
    providerSessionId: null;
    providerTransactionId: string;
    systemProvider: number;
    targetCurrentBalance: number;
    type: number;
    updateDate: string;
    version: number;
}

const initialState: BalanceState = {
    balance: {
        totalCount: 0,
        data: {
            balances: [],
        },
    },
};

const balanceSlice = createSlice({
    name: 'balance',
    initialState,
    reducers: {
        setBalance(state, action) {
            state.balance = action.payload;
        },
    },
});

const { actions, reducer } = balanceSlice;
export const { setBalance } = actions;
export default reducer;
