import { t } from 'i18next';
import { EnumValidate } from 'src/common/enum';

export function validateTRC20Helper(address) {
    const trc20AddressPattern = /^T[a-zA-Z0-9]{33}$/;

    if (address.length === 0) {
        return null;
    }

    if (!address) {
        return t('destinationAddress_no_valid');
    }

    if (typeof address !== 'string') {
        return t('destinationAddress_no_valid');
    }

    if (address.length !== EnumValidate.VALIDATE_TRC20_LENGTH) {
        return t('destinationAddress_no_valid');
    }

    if (!trc20AddressPattern.test(address)) {
        return t('destinationAddress_no_valid');
    }

    return null;
}
