import './mobileLeftSideMenu.scss';
import home from 'src/assets/images/home.svg';
import akcii from 'src/assets/images/promotions.svg';
import rules from 'src/assets/images/rules.svg';
import support from 'src/assets/images/support.svg';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDrawerLeftOpen, setOpenModal } from 'src/core/store/reducers/appSlice';
import { useTranslation } from 'react-i18next';
import { translationOptions } from 'src/core/translations/constants';
import { useSwipeable } from 'react-swipeable';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Lang } from 'src/assets/images/lang.svg';
import arrowImg from 'src/assets/images/arrow.svg';

function MobileLeftSideMenu() {
    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);

    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    const changeLanguageHandler = (lang) => {
        localStorage.setItem('locale', lang);
        i18n.changeLanguage(lang);
    };

    const navigate = useNavigate();
    const handleClickGame = (link) => {
        navigate(link);
        closeDrawerLeft();
    };

    const openModal = useCallback(
        (type) => {
            if (!type) return;
            dispatch(setOpenModal({ modal: { type } }));
        },
        [dispatch],
    );

    const closeDrawerLeft = () => {
        dispatch(setDrawerLeftOpen(false));
    };

    const swipeHandlers = useSwipeable({
        onSwipedRight: closeDrawerLeft,
        preventDefaultTouchmoveEvent: true,
        trackMouse: false,
    });

    const defaulLanguge = useMemo(() => {
        const lang = localStorage.getItem('locale');
        return translationOptions.find((el) => el.value === lang) || translationOptions[0];
    }, []);

    return (
        <div {...swipeHandlers} className="MobileLeftSideMenu">
            <div className="MobileLeftSideMenu-header">
                <div className="MobileLeftSideMenu-goback" onClick={() => dispatch(setDrawerLeftOpen(false))}>
                    <img src={arrowImg} alt="arrow" className="MobileLeftSideMenu-header-arrow" />
                    <span>{t('back')}</span>
                </div>
                <div className="MobileLeftSideMenu-header-lang">
                    <Lang /> <span>{defaulLanguge.name}</span>
                </div>
            </div>
            <div className="MobileLeftSideMenu-actions">
                <div className="MobileLeftSideMenu-actions-item" onClick={() => handleClickGame('/')}>
                    <img src={home} alt="home" className="MobileLeftSideMenu-arrow" />
                    <div className="MobileLeftSideMenu-actions-item-text">{t('main')}</div>
                </div>
                <div className="MobileLeftSideMenu-actions-item">
                    <img src={rules} alt="rules" className="MobileLeftSideMenu-arrow" />
                    <div className="MobileLeftSideMenu-actions-item-text">{t('rules')}</div>
                </div>
                <div className="MobileLeftSideMenu-actions-item">
                    <img src={support} alt="percentage" className="MobileLeftSideMenu-arrow" />
                    <div className="MobileLeftSideMenu-actions-item-text">{t('support')}</div>
                </div>
                <div className="MobileLeftSideMenu-actions-item">
                    <img src={akcii} alt="akcii" className="MobileLeftSideMenu-arrow" />
                    <div className="MobileLeftSideMenu-actions-item-text">{t('promotions')}</div>
                </div>
            </div>
            {/* {isAuthorizedState && (
                <div className="MobileLeftSideMenu-submenu">
                    <p className="MobileLeftSideMenu-submenu-title">{t('games')}</p>
                    <div className="MobileLeftSideMenu-submenu-container">
                        <span onClick={() => openModal(modalTypes.CHANCE)}>{t('chance')}</span>
                        <span onClick={() => openModal(modalTypes.OLWIN_CHANCE)}>{t('olwin_chance')}</span>
                        <span onClick={() => handleClickGame('/live-casino')}>{t('live-casino')}</span>
                        <span>poker</span>
                        <span>virtual-sports</span>
                        <span>sportsbook</span>
                        <span>live-casino-table</span>
                        <span>video-slots</span>
                        <span>table-games</span>
                        <span>video-poker</span>
                        <span>{t('virtual-games')}</span>
                        <span>scratch-cards</span>
                        <span>video-bingo</span>
                        <span>tournaments</span>
                        <span>livegames</span>
                        <span>crash-games</span>
                        <span>fast-games</span>
                        <span>OT</span>
                    </div>
                </div>
            )} */}
        </div>
    );
}

export default MobileLeftSideMenu;
