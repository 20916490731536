import './input.scss';
import classNames from 'classnames';

function Input({
    placeholder = '',
    className,
    value = '',
    onChange = () => '',
    label = '',
    hideLabel = false,
    error = null,
    id = '',
    type = 'text',
    readOnly = false,
    secondLabel = '',
    small = false,
}) {
    return (
        <div className={classNames(error ? 'inputError' : 'input', className, { ['input-small']: small })}>
            {!hideLabel && (
                <span>
                    {label} {secondLabel.length !== 0 ? <span className="second-label">{secondLabel}</span> : null}
                </span>
            )}
            <input placeholder={placeholder} value={value} onChange={onChange} type={type} id={id} readOnly={readOnly} />
            {error && <span className={classNames('error_message', className)}>{error}</span>}
        </div>
    );
}

export default Input;
