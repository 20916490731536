import { useMemo, useCallback } from 'react';
import './header.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import olwinLogo from 'src/assets/images/olwin.png';
import wallet from 'src/assets/images/wallet.svg';
import { ReactComponent as Player } from 'src/assets/images/player.svg';
import { ReactComponent as Burger } from 'src/assets/images/burger.svg';
import { ReactComponent as Plus } from 'src/assets/images/plus.svg';
import profile from 'src/assets/images/profile.png';
import RealTimeClock from 'src/components/realTimeClock/RealTimeClock';
import Dropdown from 'src/components/dropdown/Dropdown';
import { modalTypes } from 'src/components/modals/Modal';
import { tabTypes } from 'src/components/profile/Profile';
import Button from 'src/components/buttonV2/Button';
import classNames from 'classnames';
import { setDrawerRightOpen, setDrawerLeftOpen, setOpenModal } from 'src/core/store/reducers/appSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Icon } from 'src/assets/images/vector.svg';

function Header() {
    const dispatch = useDispatch();

    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);
    const user = useSelector((state) => state.user.user);
    const { t, i18n } = useTranslation();
    const location = useLocation();

    const translationOptions = [
        { label: t('EN'), value: 'en', name: 'EN' },
        { label: t('RUS'), value: 'ru', name: 'RU' },
        { label: t('HE'), value: 'he', name: 'HE' },
    ];

    const navigate = useNavigate();

    const handleClickLogo = () => {
        navigate('/');
    };

    const changeLanguageHandler = useCallback(
        (lang) => {
            localStorage.setItem('locale', lang);
            void i18n.changeLanguage(lang);
        },
        [i18n],
    );

    const defaulLanguge = useMemo(() => {
        const lang = localStorage.getItem('locale');
        return translationOptions.find((el) => el.value === lang) || translationOptions[0];
    }, []);

    const openProfile = useCallback(
        (tab, link) => {
            const modal = {
                type: modalTypes.PROFILE,
                config: { activeTab: tab || tabTypes.ACCOUNT },
            };

            dispatch(setOpenModal({ modal }));
            if (link) {
                navigate(link, { state: { background: location } });
            }
        },
        [dispatch],
    );

    const openDrawer = useCallback(
        (link) => {
            dispatch(setDrawerRightOpen(true));
            if (link) {
                navigate(link, { state: { background: location } });
            }
        },
        [dispatch],
    );

    const openRegister = useCallback(() => {
        const modal = {
            type: modalTypes.REGISTER,
        };

        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    const openLogin = useCallback(() => {
        const modal = {
            type: modalTypes.LOGIN,
        };

        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    const openLoginActionMobile = useCallback(() => {
        const modal = {
            type: modalTypes.LOGIN_ACTION_MOBILE,
        };

        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    const renderAuthHeader = () => {
        return (
            <div className="Header--rightside--auth">
                <Button title={t('deposit')} primary onClick={() => openProfile(tabTypes.DEPOSIT, '/profile')} />
                <Button
                    title={`${user.balance ? ((user.balance + user.scedualPaymentBalance) / 100)?.toFixed(2) : 0} USD`}
                    icon={<img className="button-icon" src={wallet} />}
                    onClick={() => openProfile(tabTypes.DEPOSIT, '/profile')}
                />

                <Button icon={<Player />} title={user.name} onClick={() => openProfile(tabTypes.ACCOUNT, '/profile')} />
            </div>
        );
    };

    const renderUnauthHeader = () => {
        return (
            <div className="Header--rightside--buttons">
                <Button title={t('signin')} primary onClick={openLogin} />
                <Button title={t('register')} onClick={openRegister} />
            </div>
        );
    };

    const renderMobileHeader = () => {
        return (
            <div className="Header--mobile">
                <img className="Header--mobile--logo" src={olwinLogo} onClick={handleClickLogo} />
                {!isAuthorizedState && (
                    <div className="Header--mobile--rightside">
                        <Button title={t('signin').toUpperCase()} primary onClick={openLogin} />
                        <Button title={t('register').toUpperCase()} onClick={openRegister} />
                    </div>
                )}
                {isAuthorizedState && (
                    <div className="Header--mobile--rightside">
                        <div className="Header--mobile--rightside--balance">
                            <img className="button-icon" src={wallet} />
                            <span>{`${
                                user.balance ? ((user.balance + user.scedualPaymentBalance) / 100)?.toFixed(2) : 0
                            } USD`}</span>
                            <Plus />
                        </div>
                        <Button icon={<Player />} title={''} onClick={() => openDrawer('/profile')} />
                        <div className="Header--mobile--burger" onClick={() => dispatch(setDrawerLeftOpen(true))}>
                            <Burger />
                        </div>
                    </div>
                )}
            </div>
        );
    };
    return (
        <>
            <div className={classNames('Header', { ['Header-authorized']: isAuthorizedState })}>
                <div className="Header--leftside">
                    <div className="Header--leftside--logo">
                        <img src={olwinLogo} onClick={handleClickLogo} />
                    </div>
                </div>
                <div className="Header--rightside">
                    {!isAuthorizedState && renderUnauthHeader()}
                    {isAuthorizedState && renderAuthHeader()}
                    <Dropdown
                        onSelect={({ value }) => {
                            changeLanguageHandler(value);
                        }}
                        options={translationOptions}
                        defaultSelected={defaulLanguge}
                        className="Header--rightside--dropdown"
                    />
                    <RealTimeClock />
                    <div className="Header--rightside--icon">
                        <Icon />
                    </div>
                </div>
            </div>
            {renderMobileHeader()}
        </>
    );
}

export default Header;
