import { t } from 'i18next';
import { UserGenderEnum } from '../common/provider-common-type-util/dictionary/user';

export const genderOptions = [
    { label: t('male'), value: UserGenderEnum.USER_GENDER_MALE },
    { label: t('female'), value: UserGenderEnum.USER_GENDER_FEMALE },
];

export const currencyOptions = [
    { label: 'USDT-TRC20', value: 'USDT-TRC20' },
    // { label: 'USDT-ERC20', value: 'USDT-ERC20' },
];
