import './profile.scss';
import classNames from 'classnames';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModal, setOpenModal } from 'src/core/store/reducers/appSlice';
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg';
import { ReactComponent as Enter } from 'src/assets/images/enter.svg';
import Account from './account/Account';
import History from './history/History';
import Deposit from './deposit';
import Chance from './chance/Chance';
import { useNavigate } from 'react-router-dom';

export const tabTypes = {
    CHANCE: 'CHANCE',
    OLWIN_CHANCE: 'OLWIN_CHANCE',
    ACCOUNT: 'ACCOUNT',
    DEPOSIT: 'BALANCE',
    HISTORY: 'HISTORY',
    UPLOAD_DOCS: 'UPLOAD_DOCS',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    EXIT: 'EXIT',
};

const tabs = [
    { type: tabTypes.ACCOUNT },
    { type: tabTypes.DEPOSIT },
    { type: tabTypes.HISTORY },
    { type: tabTypes.CHANGE_PASSWORD },
];

function Profile({ modal }) {
    const [activeTab, setActiveTab] = useState(null);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (modal?.config?.activeTab) {
            setActiveTab(tabs.find((el) => el.type === modal?.config?.activeTab) || activeTab);
        } else {
            setActiveTab(tabs[0]);
        }
    }, []);

    const openModal = useCallback(
        (type) => {
            if (!type) return;
            dispatch(setOpenModal({ modal: { type } }));
        },
        [dispatch],
    );

    const onSetTab = (tab) => {
        switch (tab?.type) {
            case tabTypes.CHANGE_PASSWORD:
                return openModal(modalTypes.CHANGE_PASSWORD);
            case tabTypes.EXIT:
                return openModal(modalTypes.EXIT);
            case tabTypes.UPLOAD_DOCS:
                return openModal(modalTypes.UPLOAD_DOCS);
            default:
                return setActiveTab(tab);
        }
    };

    const renderContent = () => {
        switch (activeTab?.type) {
            case tabTypes.ACCOUNT:
                return <Account />;
            case tabTypes.DEPOSIT:
                return <Deposit />;
            case tabTypes.HISTORY:
                return <History />;
            case tabTypes.CHANCE:
                return <Chance modalType={tabTypes.CHANCE} />;
            case tabTypes.OLWIN_CHANCE:
                return <Chance modalType={tabTypes.OLWIN_CHANCE} />;
            default:
                return null;
        }
    };

    const handleCloseModal = () => {
        dispatch(setCloseModal(modalTypes.PROFILE));
        if (window.history.state && window.history.state.idx > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="Profile">
            <div className="Profile-xmark" onClick={handleCloseModal}>
                <CloseIcon />
            </div>
            <div className="Profile-tabs">
                {tabs.map((tab) => {
                    return (
                        <div
                            onClick={() => onSetTab(tab)}
                            key={tab.type}
                            className={classNames('Profile-tab', { ['Profile-tab-active']: tab.type === activeTab?.type })}
                        >
                            <span>{t(tab.type?.toLowerCase())}</span>
                        </div>
                    );
                })}
                <div onClick={() => onSetTab({ type: tabTypes.EXIT })} className="Profile-exit">
                    <Enter />
                    <span>{t(tabTypes.EXIT?.toLowerCase())}</span>
                </div>
            </div>
            <div
                style={activeTab?.type === tabTypes.HISTORY ? { padding: 0, paddingRight: '10px' } : {}}
                className="Profile-content"
            >
                {renderContent()}
            </div>
        </div>
    );
}

export default Profile;
