import { createSlice } from '@reduxjs/toolkit';

export interface ISportList {
    id: number;
    name: string;
}

interface AppState {
    sports: ISportList[];
}

const initialState: AppState = {
    sports: [],
};

const sportSlice = createSlice({
    name: 'sports',
    initialState,
    reducers: {
        setSports(state, action) {
            state.sports = action.payload;
        },
    },
});

const { actions, reducer } = sportSlice;
export const { setSports } = actions;
export default reducer;
