import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// const setCloseModalTTT = createAction<string, 'type'>('setCloseModal_T');

interface AppState {
    isLoading: boolean;
    isAuthorizedState: boolean;
    drawerRightOpen: boolean;
    drawerLeftOpen: boolean;
    modals: {
        type: string;
        config: Record<string, unknown>;
    }[];
}

const initialState: AppState = {
    isLoading: true,
    isAuthorizedState: false,
    drawerLeftOpen: false,
    drawerRightOpen: false,
    modals: [],
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setLoading(state) {
            state.isLoading = false;
        },
        setIsAuthorizedState(state, action) {
            state.isAuthorizedState = action.payload;
        },
        setDrawerLeftOpen(state, action) {
            state.drawerLeftOpen = action.payload;
        },
        setDrawerRightOpen(state, action) {
            state.drawerRightOpen = action.payload;
        },
        setCloseModal(state, action) {
            state.modals = state.modals.filter((el) => el.type !== action.payload);
        },
        setOpenModal(state, action) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            state.modals = [...state.modals, action.payload.modal];
        },
        resetAllModals(state) {
            state.modals = [];
        },
        setCloseModalTTT: (state, action: PayloadAction<string>): void => {
            state.modals = state.modals.filter((el) => el.type !== action.payload);
        },
    },
});

const { actions, reducer } = appSlice;
export const {
    setLoading,
    setIsAuthorizedState,
    setDrawerRightOpen,
    setDrawerLeftOpen,
    setCloseModal,
    setOpenModal,
    setCloseModalTTT,
    resetAllModals,
} = actions;
export default reducer;
