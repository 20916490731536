/* eslint-disable no-unused-vars */
export class ChanceDictionary {
  // Chance type
  static CHANCE_TYPE: number = 500;
  static CHANCE_TYPE_SPEED: number = 501;

  // Chance bet type
  static CHANCE_BET_TYPE_ONE: number = 1;
  static CHANCE_BET_TYPE_TWO: number = 2;
  static CHANCE_BET_TYPE_THREE: number = 3;
  static CHANCE_BET_TYPE_FOUR: number = 4;

  static CHANCE_BET_TYPE_ONE_RATIO: number = 5;
  static CHANCE_BET_TYPE_TWO_RATIO: number = 30;
  static CHANCE_BET_TYPE_THREE_RATIO: number = 300;
  static CHANCE_BET_TYPE_FOUR_RATIO: number = 2000;

  static CHANCE_BET_TYPE_TWO_LOSE_PERCENT_ONE: number = 50;
  static CHANCE_BET_TYPE_THREE_LOSE_PERCENT_DOUBLE: number = 70;
  static CHANCE_BET_TYPE_THREE_LOSE_PERCENT_ONE: number = 30;
  static CHANCE_BET_TYPE_FOUR_LOSE_PERCENT_TRIPLE: number = 80;
  static CHANCE_BET_TYPE_FOUR_LOSE_PERCENT_DOUBLE: number = 50;
  static CHANCE_BET_TYPE_FOUR_LOSE_PERCENT_ONE: number = 20;

  // Chance status
  static GAME_STATUS_CREATED: number = 1;
  static GAME_STATUS_STARTED: number = 2;
  static GAME_STATUS_ENDED: number = 3;
  static GAME_STATUS_FAILED: number = 4;

  // Fail statuses
  static GAME_STATUS_NOT_ENOUGH_PLAYERS: number = 1;

  // Default value
  static DEFAULT_CASHBACK_PERCENT: number = 10;
}

export enum ChanceTypeEnum {
  GAME_TYPE_CHANCE = ChanceDictionary.CHANCE_TYPE,
  GAME_TYPE_SPEED_CHANCE = ChanceDictionary.CHANCE_TYPE_SPEED
}

export enum ChanceBetTypeEnum {
  CHANCE_BET_TYPE_ONE = ChanceDictionary.CHANCE_BET_TYPE_ONE,
  CHANCE_BET_TYPE_TWO = ChanceDictionary.CHANCE_BET_TYPE_TWO,
  CHANCE_BET_TYPE_THREE = ChanceDictionary.CHANCE_BET_TYPE_THREE,
  CHANCE_BET_TYPE_FOUR = ChanceDictionary.CHANCE_BET_TYPE_FOUR
}

export enum GameStatusEnum {
  GAME_STATUS_CREATED = ChanceDictionary.GAME_STATUS_CREATED,
  GAME_STATUS_STARTED = ChanceDictionary.GAME_STATUS_STARTED,
  GAME_STATUS_ENDED = ChanceDictionary.GAME_STATUS_ENDED,
  GAME_STATUS_FAILED = ChanceDictionary.GAME_STATUS_FAILED
}

export enum GameFailStatusEnum {
  GAME_STATUS_NOT_ENOUGH_PLAYERS = ChanceDictionary.GAME_STATUS_NOT_ENOUGH_PLAYERS
}
