/* eslint-disable */
import React, { FC, useState } from 'react';
import { ReactComponent as Arrow } from 'src/assets/images/icon-accordion.svg';
import { ICountryResource } from 'src/views/Sport/type';
import './MenuSportAccardion.scss';
import CountryAccardion from '../countryAccardion';

interface IProps {
    name: string;
    id: number;
    countries: ICountryResource[];
}

const MenuSportAccardion: FC<IProps> = ({ name, id, countries }) => {
    const [show, setShow] = useState(false);

    return (
        <div key={id} className="MenuSportAccardion">
            <div className="MenuSportAccardion-item" onClick={() => setShow(!show)}>
                <div className="MenuSportAccardion-item-content">
                    <span className="MenuSportAccardion-item-icon" />
                    <span>{name}</span>
                </div>
                <div className={show ? 'MenuSportAccardion-item-arrow-active' : 'MenuSportAccardion-item-arrow'}>
                    <Arrow />
                </div>
            </div>

            {show && (
                <div className="MenuSportAccardion-list">
                    {countries.map((item) => (
                        <CountryAccardion sportId={id} item={item} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default MenuSportAccardion;
