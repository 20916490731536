// ISO 3166-1 Alpha-2
export const countryCode = [
    // 'AL', // Albania
    // 'AD', // Andorra
    // 'AM', // Armenia
    // 'AT', // Austria
    // 'AZ', // Azerbaijan
    // 'BY', // Belarus
    // 'BE', // Belgium
    // 'BA', // Bosnia and Herzegovina
    // 'BG', // Bulgaria
    // 'HR', // Croatia
    // 'CY', // Cyprus
    // 'CZ', // Czech Republic
    // 'DK', // Denmark
    // 'EE', // Estonia
    // 'FI', // Finland
    // 'FR', // France
    // 'GE', // Georgia
    'DE', // Germany
    // 'GR', // Greece
    // 'HU', // Hungary
    // 'IS', // Iceland
    // 'IE', // Ireland
    // 'IT', // Italy
    // 'KZ', // Kazakhstan (partly in Europe)
    // 'XK', // Kosovo
    // 'LV', // Latvia
    // 'LI', // Liechtenstein
    // 'LT', // Lithuania
    // 'LU', // Luxembourg
    // 'MT', // Malta
    // 'MD', // Moldova
    // 'MC', // Monaco
    // 'ME', // Montenegro
    // 'NL', // Netherlands
    // 'MK', // North Macedonia
    // 'NO', // Norway
    // 'PL', // Poland
    // 'PT', // Portugal
    // 'RO', // Romania
    // 'SM', // San Marino
    // 'RS', // Serbia
    // 'SK', // Slovakia
    // 'SI', // Slovenia
    // 'ES', // Spain
    // 'SE', // Sweden
    // 'CH', // Switzerland
    // 'GB', // United Kingdom
    'TR', // Turkey (partly in Europe)
    // 'RU', // Russia
    'IL', // Israel
];

export const countryMap = {
    // 'Albania': 'AL',
    // 'Andorra': 'AD',
    // 'Armenia': 'AM',
    // 'Austria': 'AT',
    // 'Azerbaijan': 'AZ',
    // 'Belarus': 'BY',
    // 'Belgium': 'BE',
    // 'Bosnia and Herzegovina': 'BA',
    // 'Bulgaria': 'BG',
    // 'Croatia': 'HR',
    // 'Cyprus': 'CY',
    // 'Czech Republic': 'CZ',
    // 'Denmark': 'DK',
    // 'Estonia': 'EE',
    // 'Finland': 'FI',
    // 'France': 'FR',
    // 'Georgia': 'GE',
    Germany: 'DE',
    // 'Greece': 'GR',
    // 'Hungary': 'HU',
    // 'Iceland': 'IS',
    // 'Ireland': 'IE',
    // 'Italy': 'IT',
    // 'Kazakhstan': 'KZ',
    // 'Kosovo': 'XK',
    // 'Latvia': 'LV',
    // 'Liechtenstein': 'LI',
    // 'Lithuania': 'LT',
    // 'Luxembourg': 'LU',
    // 'Malta': 'MT',
    // 'Moldova': 'MD',
    // 'Monaco': 'MC',
    // 'Montenegro': 'ME',
    // 'Netherlands': 'NL',
    // 'North Macedonia': 'MK',
    // 'Norway': 'NO',
    // 'Poland': 'PL',
    // 'Portugal': 'PT',
    // 'Romania': 'RO',
    // 'San Marino': 'SM',
    // 'Serbia': 'RS',
    // 'Slovakia': 'SK',
    // 'Slovenia': 'SI',
    // 'Spain': 'ES',
    // 'Sweden': 'SE',
    // 'Switzerland': 'CH',
    // 'United Kingdom': 'GB',
    Turkey: 'TR',
    // 'Russia': 'RU',
    Israel: 'IL',
};
