import { t } from 'i18next';
import { EnumValidate } from '../../../common/enum';

export const validationGameName = (value) => {
    if (value.length === 0) return null;
    else if (
        value.length < EnumValidate.VALIDATE_NAME_GAME_LENGTH_MIN ||
        value.length > EnumValidate.VALIDATE_NAME_GAME_LENGTH_MAX
    )
        return t('validate_longer_2');
    return null;
};
