import { t } from 'i18next';
import React, { useCallback, useEffect } from 'react';
import './games.scss';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as Search } from 'src/assets/images/search.svg';
import Input from 'src/components/inputV2/input';
import { modalTypes } from 'src/components/modals/Modal';
import { setDrawerRightOpen, setOpenModal } from 'src/core/store/reducers/appSlice';
import { setNames } from 'src/core/store/reducers/gamesSlice';
import store, { useSelector } from 'src/core/store/store';

const Games = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isMobile = window.innerWidth <= 900;
    const name = useSelector((state: { games: { name: string } }) => state.games.name);
    const isAuthorizedState = useSelector((state) => state.app.isAuthorizedState);

    const openLogin = useCallback(() => {
        const modal = {
            type: modalTypes.LOGIN,
        };

        dispatch(setOpenModal({ modal }));
    }, [dispatch]);

    const handleChangeInput = (e) => {
        store.dispatch(setNames(e.target.value));
    };

    const openModal = useCallback(
        (type: string, link: string) => {
            if (!isAuthorizedState) {
                openLogin();
                return;
            }
            if (!type) return;
            dispatch(setOpenModal({ modal: { type } }));
            navigate(link, { state: { background: location } });
        },
        [dispatch, navigate, location],
    );

    const handleClickGame = (link: string) => {
        navigate(link);
    };

    useEffect(() => {
        if (location.pathname === '/chance') {
            openModal(modalTypes.CHANCE, '/chance');
        }
        if (location.pathname === '/chance-olwin') {
            openModal(modalTypes.OLWIN_CHANCE, '/chance-olwin');
        }
        if (location.pathname === '/profile') {
            if (!isMobile) {
                openModal(modalTypes.PROFILE, '/profile');
            } else {
                dispatch(setDrawerRightOpen(true));
            }
        }
    }, []);

    return (
        <div className="Games-wrapper">
            <div className="Games">
                <div className="Games-list">
                    <span
                        onClick={() => handleClickGame('/sport')}
                        className={location.pathname === '/sport' ? 'Games-active' : ''}
                    >
                        SPORT
                    </span>
                    <span onClick={() => openModal(modalTypes.CHANCE, '/chance')}>{t('chance')}</span>
                    <span onClick={() => openModal(modalTypes.OLWIN_CHANCE, '/chance-olwin')}>{t('olwin_chance')}</span>
                    <span
                        className={location.pathname === '/live-casino' ? 'Games-active' : ''}
                        onClick={() => handleClickGame('/live-casino')}
                    >
                        {t('live-casino')}
                    </span>
                    <span>CASINO</span>
                    <span>TV GAMES</span>
                    <span>VIRTUAL SPORTS</span>
                    <span>BET GAMES</span>
                    <span>KENO</span>
                    <span>SKILL GAMES</span>
                </div>
            </div>
            {location.pathname === '/live-casino' && (
                <div className="Games-input">
                    <Input
                        icon={<Search />}
                        hideLabel={false}
                        placeholder={t('game_name')}
                        value={name}
                        id="name"
                        onChange={handleChangeInput}
                    />
                </div>
            )}
        </div>
    );
};

export default Games;
