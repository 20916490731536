import { removeAuthToken, setAuthToken, getAuthToken } from 'src/utils/cookies';
import { api } from 'src/api/api';
import {
    setIsAuthorizedState,
    setLoading,
    setCloseModal,
    setDrawerRightOpen,
    setOpenModal,
} from 'src/core/store/reducers/appSlice';
import { setUser, deleteUSer } from 'src/core/store/reducers/userSlice';
import store from 'src/core/store/store';
import { getProfile } from 'src/core/engines/UserEngine';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';
import { checkPermission } from 'src/utils/helpers';
import { setVerification } from '../store/reducers/userSlice';
import { jwtDecode } from 'jwt-decode';

export const logoutFromAccount = () => {
    delete api.defaults.headers.common['Authorization'];
    removeAuthToken();
    store.dispatch(setIsAuthorizedState(false));
    store.dispatch(deleteUSer());
    store.dispatch(setDrawerRightOpen(false));
    appDispatch(setCloseModalTTT(String(modalTypes.PROFILE)));
};

export const getStatus = async () => {
    const response = await api.get('/document/status');
    if (response.data) {
        store.dispatch(setVerification(response.data.status));
    } else {
        store.dispatch(setVerification(0));
    }
};

export const login = async (body) => {
    const { data } = await api.post('/auth/login', body);
    if (data) {
        const validate = await checkPermission(data.ACCESS_TOKEN);
        const resetPassword = sessionStorage.getItem('newPassword');

        if (validate) {
            setAuthToken(data.ACCESS_TOKEN);
            store.dispatch(setIsAuthorizedState(true));
            store.dispatch(setUser(data.user));
            store.dispatch(setCloseModal(modalTypes.LOGIN));
            if (resetPassword) {
                const modal = {
                    type: modalTypes.CHANGE_PASSWORD,
                };
                store.dispatch(setOpenModal({ modal }));
            }
            getStatus();
        }
    }
};

const isTokenExpired = (token) => {
    if (!token) return true;

    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000;
        return decoded.exp < currentTime;
    } catch (error) {
        return true;
    }
};

export const initData = async () => {
    const token = getAuthToken();
    if (token) {
        const isExpired = isTokenExpired(token);
        if (!isExpired) {
            const { data } = await getProfile();
            if (data) {
                store.dispatch(setIsAuthorizedState(true));
            }
        } else {
            removeAuthToken();
        }
    }

    store.dispatch(setLoading(false));
};
