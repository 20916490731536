import './modal.scss';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCloseModal } from 'src/core/store/reducers/appSlice';
import Profile from 'src/components/profile/Profile';
import Register from 'src/components/register/Register';
import Login from 'src/components/login/Login';
import LoginActonsMobile from 'src/components/loginActonsMobile/LoginActonsMobile';
import ResetPassword from 'src/components/resetPassword/ResetPassword';
import NewPassword from 'src/components/newPassword/NewPassword';
import ChangePassword from 'src/components/changePassword/ChangePassword';
import Exit from 'src/components/exit/Exit';
import UploadDocs from 'src/components/uploadDocs/UploadDocs';
import Account from '../profile/account/Account';
import History from '../profile/history/History';
import Deposit from '../profile/deposit';
import Chance from '../profile/chance/Chance';
import { useNavigate } from 'react-router-dom';
// import { useSwipeable } from 'react-swipeable';

export const modalTypes = {
    PROFILE: 'PROFILE',
    REGISTER: 'REGISTER',
    LOGIN: 'LOGIN',
    LOGIN_ACTION_MOBILE: 'LOGIN_ACTION_MOBILE',
    RESET_PASSWORD: 'RESET_PASSWORD',
    CHANGE_PASSWORD: 'CHANGE_PASSWORD',
    EXIT: 'EXIT',
    ACCOUNT: 'ACCOUNT',
    UPLOAD_DOCS: 'UPLOAD_DOCS',
    NEW_PASSWORD: 'NEW_PASSWORD',
    HISTORY: 'HISTORY',
    DEPOSIT: 'DEPOSIT',
    CHANCE: 'CHANCE',
    OLWIN_CHANCE: 'OLWIN_CHANCE',
};

function ModalComponent() {
    const modals = useSelector((state) => state.app.modals);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const renderModalWrapper = useCallback((children, type, className) => {
        const handleClick = () => {
            dispatch(setCloseModal(type));
            if (type === modalTypes.CHANCE || type === modalTypes.OLWIN_CHANCE || type === modalTypes.PROFILE) {
                if (window.history.state && window.history.state.idx > 2) {
                    navigate(-1);
                } else {
                    navigate('/');
                }
            }
        };

        return (
            <div key={type} className={classNames('Modal', className)} onClick={handleClick}>
                <div onClick={(e) => e.stopPropagation()}>{children}</div>
            </div>
        );
    }, []);

    return (
        <>
            {modals.map((modal) => {
                if (modal.type === modalTypes.PROFILE) {
                    return renderModalWrapper(<Profile modal={modal} />, modal.type);
                } else if (modal.type === modalTypes.REGISTER) {
                    return renderModalWrapper(<Register />, modal.type);
                } else if (modal.type === modalTypes.LOGIN) {
                    return renderModalWrapper(<Login />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.LOGIN_ACTION_MOBILE) {
                    return renderModalWrapper(<LoginActonsMobile />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.RESET_PASSWORD) {
                    return renderModalWrapper(<ResetPassword />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.CHANGE_PASSWORD) {
                    return renderModalWrapper(<ChangePassword />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.EXIT) {
                    return renderModalWrapper(<Exit />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.UPLOAD_DOCS) {
                    return renderModalWrapper(<UploadDocs />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.NEW_PASSWORD) {
                    return renderModalWrapper(<NewPassword />, modal.type);
                } else if (modal.type === modalTypes.ACCOUNT) {
                    return renderModalWrapper(<Account />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.HISTORY) {
                    return renderModalWrapper(<History />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.DEPOSIT) {
                    return renderModalWrapper(<Deposit />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.CHANCE) {
                    return renderModalWrapper(<Chance modalType={modal.type} />, modal.type, 'Modal-dark-desktop');
                } else if (modal.type === modalTypes.OLWIN_CHANCE) {
                    return renderModalWrapper(<Chance modalType={modal.type} />, modal.type, 'Modal-dark-desktop');
                }

                return null;
            })}
        </>
    );
}

export default ModalComponent;
