import './PlayGame.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getGame } from '../../core/engines/GamesEngine';
import loading from '../../assets/images/loading.gif';

function PlayGame() {
    const [isLoading, setIsLoading] = useState(false);
    const [showGame, setShowGame] = useState(null);
    const { gameType, gameCode } = useParams();

    const loadData = async () => {
        setIsLoading(true);
        const resp = await getGame(gameType, gameCode);
        if (resp) {
            setShowGame(resp);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="PlayGame">
            {isLoading ? (
                <div className="Sport-loading">
                    <img src={loading} alt="" />
                </div>
            ) : (
                <div className="PlayGame-game">
                    {showGame !== null && (
                        <>
                            <iframe src={showGame} allow="accelerometer; autoplay; fullscreen; gyroscope; magnetometer"></iframe>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default PlayGame;
