import { createSlice } from '@reduxjs/toolkit';

export interface IGames {
    Category: string;
    GameCode: string;
    GameName: string;
    GameType: number;
    ImageUrl: string;
    ProviderCode: string;
    ProviderGameType: null;
}

interface AppState {
    games: IGames[];
    name: string;
}

const initialState: AppState = {
    games: [],
    name: '',
};

const appSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setGames(state, action) {
            state.games = action.payload;
        },
        setNames(state, action) {
            state.name = action.payload;
        },
    },
});

const { actions, reducer } = appSlice;
export const { setGames, setNames } = actions;
export default reducer;
