/* eslint-disable */
import React, { FC, useEffect, useState, useRef } from 'react';
import { ReactComponent as Arrow } from 'src/assets/images/icon-accordion.svg';
import { ReactComponent as Football } from 'src/assets/images/sports/football.svg';
import { ReactComponent as Baseball } from 'src/assets/images/sports/baseball.svg';
import { ReactComponent as Esports } from 'src/assets/images/sports/esports.svg';
import { ReactComponent as Cricket } from 'src/assets/images/sports/cricket.svg';
import { ReactComponent as Tennis } from 'src/assets/images/sports/tennis.svg';
import { ReactComponent as IceHockey } from 'src/assets/images/sports/iceHockey.svg';
import { ReactComponent as Volleyball } from 'src/assets/images/sports/volleyball.svg';
import { ReactComponent as Basketball } from 'src/assets/images/sports/basketball.svg';
import loading from 'src/assets/images/loading.gif';

import { getMatchBySport } from 'src/core/engines/SportEngine';
import './sportAccardion.scss';

interface IProps {
    name: string;
    id: number;
    status: number;
    index: number;
}

const SportAccardion: FC<IProps> = ({ id, name, status, index }) => {
    const [matches, setMatches] = useState([]);
    const [show, setShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);

    const loadData = async () => {
        setIsLoading(true);
        const resp = await getMatchBySport(id, status);
        if (resp) {
            setMatches(resp.data.list);
            const defaultShow = index === 0 ? true : false;
            setShow(defaultShow);
            setIsLoading(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (debounceTimeoutRef.current) {
            clearTimeout(debounceTimeoutRef.current);
        }

        debounceTimeoutRef.current = setTimeout(() => {
            loadData();
        }, 300);

        return () => {
            if (debounceTimeoutRef.current) {
                clearTimeout(debounceTimeoutRef.current);
            }
        };
    }, [status]);

    const factoryShowIconSport = (name: string) => {
        switch (name) {
            case 'Football':
                return <Football />;
            case 'Basketball':
                return <Basketball />;
            case 'Tennis':
                return <Tennis />;
            case 'Cricket':
                return <Cricket />;
            case 'Baseball':
                return <Baseball />;
            case 'Ice Hockey':
                return <IceHockey />;
            case 'Volleyball':
                return <Volleyball />;
            case 'Esports':
                return <Esports />;

            default:
                break;
        }
    };

    return (
        <div key={id} className="SportAccardion">
            <div
                className={`SportAccardion-header ${!show && 'show'}`}
                onClick={() => setShow(!show)}
                style={index === 0 ? { borderRadius: !show ? '10px 0px 10px 10px' : '10px 0px 0px 0px' } : {}}
            >
                <div className="SportAccardion-header-left-content">
                    <Arrow className={show ? 'SportAccardion-active-arrow' : 'SportAccardion-arrow'} />
                    <span className="SportAccardion-header-left-content-title">{name}</span>
                    {isLoading ? (
                        <div className="SportAccardion-loading-count">
                            <img src={loading} alt="" />
                        </div>
                    ) : (
                        <div className="SportAccardion-header-left-content-count">{matches.length}</div>
                    )}
                </div>
                <div className="SportAccardion-header-right-content">{factoryShowIconSport(name)}</div>
            </div>
            {show && (
                <div className="SportAccardion-content">
                    <div className="SportAccardion-content-header">
                        <div className="SportAccardion-content-header-left-content">
                            <span>Event</span>
                        </div>
                        <div className="SportAccardion-content-header-right-content">
                            <span>Time</span>
                            <span>Win1</span>
                            <span>Draw</span>
                            <span>Win2</span>
                            <span>More</span>
                        </div>
                    </div>

                    {!isLoading ? (
                        <div className="SportAccardion-content-list">
                            {matches.slice(0, expanded ? matches.length : 5).map((item) => (
                                <div key={item.id} className="SportAccardion-content-list-item">
                                    <div className="SportAccardion-content-ist-item-left-content">
                                        <div className="SportAccardion-content-list-item-left-content-first-name">
                                            <span>{item.scores.ft.score[0]}</span>
                                            <span>{item.away?.name}</span>
                                        </div>
                                        <div className="SportAccardion-content-list-item-left-content-second-name">
                                            <span>{item.scores.ft.score[1]}</span>
                                            <span>{item.home?.name}</span>
                                        </div>
                                    </div>
                                    <div className="SportAccardion-content-list-item-right-content">
                                        <span>53</span>
                                        <span>3.25</span>
                                        <span>2.7</span>
                                        <span>3.85</span>
                                        <span>+291</span>
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className="SportAccardion-loading">
                            <img src={loading} alt="" />
                        </div>
                    )}
                    {matches.length > 5 && (
                        <div className="SportAccardion-footer" onClick={() => setExpanded(!expanded)}>
                            <Arrow className={expanded ? 'SportAccardion-footer-active-arrow' : 'SportAccardion-footer-arrow'} />
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SportAccardion;
