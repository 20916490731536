import React, { useEffect } from 'react';
import { initData } from 'src/core/engines/AppEngine';
import './App.scss';
import { useSelector } from 'src/core/store/store';
import AppRouter from 'src/router/AppRouter';

function App() {
    const isLoading = useSelector((state) => state.app.isLoading);

    useEffect(() => {
        initData();
    }, []);

    return <div className="App">{isLoading ? <div className="App-loading">Loading...</div> : <AppRouter />}</div>;
}

export default App;
