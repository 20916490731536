import { createSlice } from '@reduxjs/toolkit';

const userSlice = createSlice({
    name: 'user',
    initialState: {
        user: {},
    },
    reducers: {
        setUser(state, action) {
            state.user = { ...action.payload, emails: action.payload.emails.sort((a, b) => b.id - a.id) };
        },
        deleteUSer(state) {
            state.user = {};
        },
        setVerification(state, action) {
            state.user = { ...state.user, verification: action.payload };
        },
        setUserUpdate(state, action) {
            state.user = { verification: state.user.verification, ...action.payload };
        },
        decreaseBalance(state, action) {
            state.user = { ...state.user, balance: state.user.balance - action.payload };
        },
    },
});

const { actions, reducer } = userSlice;
export const { deleteUSer, setUser, setVerification, setUserUpdate, decreaseBalance } = actions;
export default reducer;
