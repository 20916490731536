import React from 'react';
// import { ReactComponent as Logo } from 'src/assets/images/not-page-logo.svg';
import './404.scss';
import { useTranslation } from 'react-i18next';

const NotPage = () => {
    const { t } = useTranslation();

    return (
        <div className="NotPage">
            <span>OLWIN</span>
            <p>{t('the_current')}</p>
            <a href="/">{t('return_home')}</a>
        </div>
    );
};

export default NotPage;
