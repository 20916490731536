import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useDispatch } from 'react-redux';
import { api } from 'src/api/api';
import loading from 'src/assets/images/loading.gif';
import { ReactComponent as CloseIcon } from 'src/assets/images/close_icon.svg';
import Button from 'src/components/buttonV2/Button';
import Input from 'src/components/inputV2/input';
import './chance.scss';
import RadioButton from 'src/components/radioButton/RadioButton';
import { setCloseModal } from 'src/core/store/reducers/appSlice';

import { cards } from '../../../utils/utils';
import { tabTypes } from '../Profile';
import { decreaseBalance } from '../../../core/store/reducers/userSlice';
import { EnumValidate } from '../../../common/enum';
import { useNavigate } from 'react-router-dom';

const Chance = ({ modalType }) => {
    const [bid, setBid] = useState('');
    const [cardActive, setCardActive] = useState([]);
    const [isLoadingChance, setIsloadingChance] = useState(false);
    const [isLoading, setIsloading] = useState(false);
    const [chance, setChance] = useState([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();

    let interval = null;

    const handleChangeChance = (id, value, key) => {
        const element = { id, value, key };

        const findItemTwo = cardActive.find((item) => item.id === id && item.value === value);
        if (findItemTwo) {
            const filter = cardActive.filter((item) => item.id !== id);
            setCardActive(filter);
            return;
        }
        const findItem = cardActive.find((item) => item.id === id);
        if (!findItem) {
            setCardActive([...cardActive, element]);
            return;
        }

        const mapped = cardActive.map((item) => {
            if (item.id === id) return element;
            return item;
        });

        setCardActive(mapped);
    };

    const validateBidChance = () => {
        if (bid.length === 0) return null;
        if (Number(bid) < EnumValidate.VALIDATE_BID_MIN_CHANCE) return t('validate_bid');
        return null;
    };

    const factoryTypeWithModalType = () => {
        switch (modalType) {
            case tabTypes.CHANCE:
                return 500;
            case tabTypes.OLWIN_CHANCE:
                return 501;
            default:
                break;
        }
    };

    const getChance = async () => {
        setIsloadingChance(true);
        const resp = await api.get(`/chance?type=${factoryTypeWithModalType()}&status=2`);
        if (resp.data) {
            setChance(resp.data.data);
            setIsloadingChance(false);
        } else {
            setIsloadingChance(false);
        }
    };

    const updateChance = async () => {
        const resp = await api.get(`/chance?type=${factoryTypeWithModalType()}&status=2`);
        if (resp.data) {
            setChance(resp.data.data);
        }
    };

    useEffect(() => {
        getChance();
        interval = setInterval(() => {
            updateChance();
        }, 10000);
        return () => {
            clearInterval(interval);
        };
    }, [modalType]);

    const showActiveRadioButton = (id, value) => {
        const active = cardActive.find((item) => item.id === id && item.value === value);
        if (active) return true;
        return false;
    };

    const showCountChance = () => {
        const { length } = cardActive;
        switch (length) {
            case 0:
            case 1:
                return 1;
            case 2:
                return 2;
            case 3:
                return 3;
            case 4:
                return 4;

            default:
                break;
        }
    };

    const handleChangeBid = (e) => {
        const { value } = e.target;
        const onlyDigits = /^\d*$/;

        if (onlyDigits.test(value)) {
            setBid(value);
        }
    };

    const isDisableButton = () => {
        if (Number(bid) < EnumValidate.VALIDATE_BID_MIN_CHANCE) return true;
        if (cardActive.length === 0) return true;
        return false;
    };

    const transformArrayToObject = (arr) => {
        const transformedObject = {};
        arr.forEach((obj) => {
            const key = obj.key;
            transformedObject[key] = obj.value;
        });
        return transformedObject;
    };

    const sendBid = async () => {
        setIsloading(true);
        const betChance = JSON.stringify({ type: cardActive.length, data: transformArrayToObject(cardActive) });
        const options = {
            price: Number(bid) * 100,
            chance: chance[0].id,
            betChance,
        };

        const resp = await api.post(`/ticket/create`, options);
        if (resp.data) {
            await dispatch(decreaseBalance(Number(bid) * 100));
            setBid('');
            setCardActive([]);
            setIsloading(false);
            NotificationManager.success(`${t('success')}`, '', 3500);
        } else {
            setIsloading(false);
        }
    };

    const handleCloseModal = () => {
        dispatch(setCloseModal(modalType));
        if (window.history.state && window.history.state.idx > 2) {
            navigate(-1);
        } else {
            navigate('/');
        }
    };

    return (
        <div className="Chance">
            {isLoadingChance ? (
                <div className="Chance-loading">
                    <img src={loading} alt="" />
                </div>
            ) : (
                <>
                    <div className="Chance-title">
                        <span>{t(factoryTypeWithModalType() === 500 ? 'chance' : 'olwin_chance')} </span>
                        {chance.length !== 0 && cardActive.length !== 0 && (
                            <div className="Chance-title-count">
                                <span>{showCountChance()}</span>
                            </div>
                        )}
                    </div>
                    {chance.length !== 0 ? (
                        <div className="Chance-content">
                            <div className="Chance-card">
                                {cards.map((item, index) => (
                                    <div key={index} style={index === 3 ? { border: 'none' } : {}} className="Chance-card-item">
                                        {item.map((el, i) =>
                                            el.title ? (
                                                <div
                                                    key={i}
                                                    onClick={() => handleChangeChance(index, el.value, el.key)}
                                                    className="Chance-card-item-cards"
                                                >
                                                    <span
                                                        className={
                                                            showActiveRadioButton(index, el.value) &&
                                                            'Chance-card-item-active-title'
                                                        }
                                                    >
                                                        {el.title}
                                                    </span>
                                                    <RadioButton
                                                        hideLabel
                                                        onChange={() => {}}
                                                        value={showActiveRadioButton(index, el.value)}
                                                    />
                                                </div>
                                            ) : (
                                                <div key={i} className="Chance-card-item-cardImage">
                                                    <img className="Chance-card-item-cards-images" src={el.images} alt="" />
                                                </div>
                                            ),
                                        )}
                                    </div>
                                ))}
                            </div>
                            <div className="Chance-footer">
                                <Input
                                    className="Chance-input"
                                    placeholder={t('bid')}
                                    value={bid}
                                    onChange={handleChangeBid}
                                    error={validateBidChance()}
                                />
                                <Button
                                    className="Chance-button"
                                    title={t('select_bid')}
                                    primary
                                    onClick={sendBid}
                                    loading={isLoading}
                                    disabled={isDisableButton()}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="Chance-no-content">
                            <span>{t('not_conent_chance')}</span>
                        </div>
                    )}
                </>
            )}
            <div className="Chance-xmark" onClick={handleCloseModal}>
                <CloseIcon />
            </div>
        </div>
    );
};

export default Chance;
