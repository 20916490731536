import React, { useState, FC } from 'react';
import './resetPassword.scss';
import { useTranslation } from 'react-i18next';
import { NotificationManager } from 'react-notifications';
import { api } from 'src/api/api';
import Button from 'src/components/button/Button';
import Input from 'src/components/input/Input';
import { modalTypes } from 'src/components/modals/Modal';
import { setCloseModalTTT } from 'src/core/store/reducers/appSlice';
import { appDispatch } from 'src/core/store/store';

import { validationPassword, validationLogin, validationEmail } from '../register/validation';

import { IResponseCode, IResponseRestorePassword } from './types';

const ResetPassword: FC = (): JSX.Element => {
    const [username, setUsername] = useState<string>('');
    const [step, setStep] = useState<number>(1);
    const [credentials, setCredentials] = useState<string>('');
    const [code, setCode] = useState<string>('');
    const [password, setPassword] = useState('');
    const [passwordSign, setPasswordSign] = useState('');
    const [disbaled, setDisbaled] = useState<boolean>(false);
    const { t } = useTranslation();

    const loginHandle = async (): Promise<void> => {
        setDisbaled(true);

        const options: { phone?: string; login: string; email?: string } = {
            login: username,
        };

        if (typeof validationEmail(credentials) !== 'string') {
            options.email = credentials;
        } else {
            options.phone = credentials;
        }

        const query = new URLSearchParams(options).toString();

        const resp: IResponseCode = await api.get(`/user/password/code?${query}`);
        if (resp.data) {
            setDisbaled(false);
            // TODO View code
            NotificationManager.warning(`${t('code')}: ${resp.data.codePayload}`, '', 20000);
            // NotificationManager.success(`${t('sent_code')}`, '', 3500);
            setStep(2);
        } else {
            switch (resp?.error?.response?.status) {
                case 404:
                    setStep(1);
                    break;
                case 403:
                case 405:
                case 406:
                    setStep(2);
                    break;

                default:
                    break;
            }
            setDisbaled(false);
        }
    };

    const resetPassword = async (): Promise<void> => {
        setDisbaled(true);
        const option = {
            login: username,
            phone: credentials,
            codePayload: code,
            newPassword: password,
            passwordSign,
        };

        const resp: IResponseRestorePassword = await api.post(`/user/password`, option);
        if (resp.data) {
            setDisbaled(false);
            NotificationManager.success(`${t('reset_password_success')}`, '', 3500);
            appDispatch(setCloseModalTTT(String(modalTypes.RESET_PASSWORD)));
            appDispatch(setCloseModalTTT(String(modalTypes.LOGIN)));
        } else {
            if (resp?.error?.response?.status === 404) {
                setStep(1);
            }
            setDisbaled(false);
        }
    };

    const isDisabledButton = () => {
        if (step === 1) {
            if (typeof validationLogin(username) === 'string' || username.length === 0) return true;
            if (credentials.length === 0) return true;
            return false;
        }

        if (step === 2) {
            if (code.length !== 8) return true;
            return false;
        }

        if (step === 3) {
            if (typeof validationPassword(password) === 'string' || password.length === 0) return true;
            if (typeof validationPassword(passwordSign) === 'string' || passwordSign.length === 0) return true;
            if (password !== passwordSign) return true;
            return false;
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    const factoryTitleButton = () => {
        if (step === 1) return t('get_code');
        if (step === 2) return t('next');
        if (step === 3) return t('reset');
    };

    const factoryActionButton = () => {
        if (step === 1) return loginHandle;
        if (step === 2) return nextStep;
        if (step === 3) return resetPassword;
    };

    return (
        <div className="ResetPassword">
            <div className="ResetPassword-xmark" onClick={() => appDispatch(setCloseModalTTT(String(modalTypes.RESET_PASSWORD)))}>
                ✕
            </div>
            <span className="ResetPassword-content-title">{t('reset_password')}</span>
            <div className="ResetPassword-content">
                {(step === 1 || step === 2) && (
                    <div className="ResetPassword-content-inputs">
                        <Input
                            name="nickname"
                            className="Login-content-inputs-input"
                            label={t('user_name_input')}
                            placeholder={t('user_name_input')}
                            value={username}
                            onChange={(event: { target: HTMLInputElement }) => {
                                setUsername(event.target.value);
                            }}
                            error={validationLogin(username)}
                        />
                        <Input
                            name="password"
                            className="ResetPassword-content-inputs-input"
                            label={t('email_phone')}
                            placeholder={t('email_phone')}
                            value={credentials}
                            onChange={(event: { target: HTMLInputElement }) => {
                                setCredentials(event.target.value);
                            }}
                        />
                        {step === 2 && (
                            <div className="ResetPassword-content-wrapper-code">
                                <span>{t('code')}:</span>
                                <Input
                                    name="number"
                                    hideLabel
                                    className="ResetPassword-content-wrapper-code-input"
                                    value={code}
                                    placeholder={t('code')}
                                    onChange={(event: { target: HTMLInputElement }) => {
                                        setCode(event.target.value);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                )}
                {step === 3 && (
                    <div className="ResetPassword-content-inputs">
                        <Input
                            name="new_password"
                            className="ResetPassword-content-inputs-input"
                            label={t('new_password')}
                            placeholder={t('new_password')}
                            value={password}
                            type="password"
                            onChange={(event: { target: HTMLInputElement }) => {
                                setPassword(event.target.value);
                            }}
                            error={validationPassword(password)}
                        />
                        <Input
                            name="phone"
                            className="ResetPassword-content-inputs-input"
                            label={t('repeat_password')}
                            placeholder={t('repeat_password')}
                            type="password"
                            value={passwordSign}
                            onChange={(event: { target: HTMLInputElement }) => {
                                setPasswordSign(event.target.value);
                            }}
                            error={validationPassword(passwordSign)}
                        />
                    </div>
                )}

                <div className="ResetPassword-buttons">
                    {step === 3 && (
                        <Button
                            className="ResetPassword-content-signin"
                            title={t('cancel')}
                            onClick={prevStep}
                            loading={disbaled}
                        />
                    )}

                    <Button
                        className="ResetPassword-content-reset"
                        title={factoryTitleButton()}
                        primary
                        onClick={factoryActionButton()}
                        disabled={isDisabledButton()}
                        loading={disbaled}
                    />
                </div>
            </div>
        </div>
    );
};

export default ResetPassword;
